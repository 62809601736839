import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from './types'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get } from '../../api/api'
import Cookies from 'js-cookie'

const token = Cookies.get('auth_token')

const initialState: State = {
  data: {},
  fetching: false,
  fetchingMore: false,
  error: false,
  PDFLoading: false,
  PDFError: false,
  FullPDFLoading: false,
  FullPDFError: false,
  FullPDFSucceed: false,
}

const contragentInfoSlice = createSlice({
  name: 'contragentInfo',
  initialState,
  reducers: {
    loadStarted(state) {
      state.data = {}
      state.fetching = true
      state.error = false
    },
    loadSucceed(state, action: PayloadAction<any>) {
      state.data = action.payload
      state.fetching = false
      state.error = false
    },
    loadFailed(state) {
      state.data = {}
      state.fetching = false
      state.error = true
    },
    loadMoreStarted(state) {
      state.fetchingMore = true
      state.error = false
    },
    loadMoreSucceed(state, action: PayloadAction<any>) {
      state.data = action.payload
      state.fetchingMore = false
      state.error = false
    },
    loadMoreFailed(state) {
      state.fetchingMore = false
      state.error = true
    },

    PDFLoadStarted(state) {
      state.PDFLoading = true
    },
    PDFLoadSucceed(state) {
      state.PDFLoading = false
    },
    PDFLoadFailed(state) {
      state.PDFLoading = false
      state.PDFError = true
    },

    FullPDFLoadStarted(state) {
      state.FullPDFLoading = true
      state.FullPDFSucceed = false
    },
    FullPDFLoadSucceed(state) {
      state.FullPDFLoading = false
      state.FullPDFSucceed = true
    },
    FullPDFLoadFailed(state) {
      state.FullPDFLoading = false
      state.FullPDFError = true
      state.FullPDFSucceed = false
    },
    FullPDFStateReset(state) {
      state.FullPDFLoading = false
      state.FullPDFError = false
      state.FullPDFSucceed = false
    },
  },
})

export const contragentInfoSearched = (id: number): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(contragentInfoSlice.actions.loadStarted())
  get(`/counterparty/${id}`)
    .then(json => {
      dispatch(contragentInfoSlice.actions.loadSucceed(json))
    })
    .catch(() => {
      dispatch(contragentInfoSlice.actions.loadFailed())
    })
}

export const contragentInfoMore = (
  id: number,
  url: string,
  filename: string,
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(contragentInfoSlice.actions.loadMoreStarted())
  dispatch(contragentInfoSlice.actions.FullPDFLoadStarted())

  const response = await fetch(url, {
    headers: {
      Authorization: 'Token ' + token,
    },
  })

  if (!response.ok) {
    dispatch(contragentInfoSlice.actions.FullPDFLoadFailed())
    alert('Во время загрузки отчета произошла ошибка...')
    return
  }

  const blob = await response.blob()
  const objectUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(objectUrl)

  dispatch(contragentInfoSlice.actions.FullPDFLoadSucceed())

  get(`/counterparty/${id}`)
    .then(json => {
      dispatch(contragentInfoSlice.actions.loadMoreSucceed(json))
    })
    .catch(() => {
      dispatch(contragentInfoSlice.actions.loadMoreFailed())
    })
}

export const PDFLoaded = (
  url: string,
  filename: string,
  pdftype?: string,
): AppThunk => async (dispatch: AppDispatch) => {
  pdftype === 'full-pdf'
    ? dispatch(contragentInfoSlice.actions.FullPDFLoadStarted())
    : dispatch(contragentInfoSlice.actions.PDFLoadStarted())

  const response = await fetch(url, {
    headers: {
      Authorization: 'Token ' + token,
    },
  })

  if (!response.ok) {
    pdftype === 'full-pdf'
      ? dispatch(contragentInfoSlice.actions.FullPDFLoadFailed())
      : dispatch(contragentInfoSlice.actions.PDFLoadFailed())
    alert('Во время загрузки отчета произошла ошибка...')
    return
  }

  const blob = await response.blob()
  const objectUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(objectUrl)

  pdftype === 'full-pdf'
    ? dispatch(contragentInfoSlice.actions.FullPDFLoadSucceed())
    : dispatch(contragentInfoSlice.actions.PDFLoadSucceed())
}

export const PDFStateReset = () => (dispatch: AppDispatch) => {
  dispatch(contragentInfoSlice.actions.FullPDFStateReset())
}

export default contragentInfoSlice.reducer
