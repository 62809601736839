import { Link } from 'react-router-dom'
import { searchBarStringSet } from '../features/search-bar/searchBarSlice'

interface Field {
  fieldName: string
  fieldNameKey: string
  renderingCondition?: (hasAccess: boolean, value: string) => boolean
  formatName?: (value: string) => Element | any
  format?: (value: string, dispatch: (arg0: any) => void) => Element | any
}

/* eslint-disable react/display-name */
export const equipmentFields: (Field | undefined)[] = [
  {
    fieldName: 'Условия поставки',
    fieldNameKey: 'terms',
    renderingCondition: hasAccess => hasAccess,
  },
  {
    fieldName: 'Пункт поставки',
    fieldNameKey: 'destination',
  },
  ,
  {
    fieldName: 'Код ТН ВЭД',
    fieldNameKey: 'code',
  },
  {
    fieldName: 'Наименование отправителя',
    fieldNameKey: 'seller',
    format: (value, dispatch) =>
      value !== '' ? (
        <Link
          to={`/counterparty-check#${encodeURIComponent(value)}`}
          onClick={() => dispatch(searchBarStringSet(''))}
        >
          {value}
        </Link>
      ) : (
        '—'
      ),
  },
  {
    fieldName: 'Страна происхождения товара',
    fieldNameKey: 'manufacturer_country',
  },
  {
    fieldName: 'Страна отправления',
    fieldNameKey: 'seller_country',
  },
  {
    fieldName: 'Изготовитель',
    fieldNameKey: 'manufacturer',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: (value, dispatch) =>
      value !== '' ? (
        <Link
          to={`/counterparty-check#${encodeURIComponent(value)}`}
          onClick={() => dispatch(searchBarStringSet(''))}
        >
          {value}
        </Link>
      ) : (
        '—'
      ),
  },
  {
    fieldName: 'Модели, артикулы...',
    fieldNameKey: 'models',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Кол-во ед. изм.',
    fieldNameKey: 'amount',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: value => Number(value).toFixed(2),
  },
  {
    fieldName: 'Единица измерения',
    fieldNameKey: 'measure_unit',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Вес нетто',
    fieldNameKey: 'net_weight',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: value => Number(value).toFixed(2),
  },
  {
    fieldName: 'Фактурная стоимость',
    fieldNameKey: 'invoice_price',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: value => Number(value).toFixed(2),
  },
  {
    fieldName: 'Таможенная стоимость',
    fieldNameKey: 'customs_value',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: value => Number(value).toFixed(2),
  },
  {
    fieldName: 'Валюта таможенной стоимости',
    fieldNameKey: 'customs_value_currency',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Таможенный орган',
    fieldNameKey: 'g071',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Дата завершения таможенного оформления',
    fieldNameKey: 'gd1',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName:
      'Дата выдачи Свидетельства о включении в Реестр таможенных брокеров (представителей)',
    fieldNameKey: 'g541d',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Дата курса валюты',
    fieldNameKey: 'g230',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Таможенный режим',
    fieldNameKey: 'g0121',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код региона отправителя',
    fieldNameKey: 'g021_region',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Итоговый адрес отправителя',
    fieldNameKey: 'g023',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Общее количество комплектов ТД',
    fieldNameKey: 'g032',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Всего наименований товаров',
    fieldNameKey: 'g05',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Количество мест',
    fieldNameKey: 'g06',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код вида таможенной декларации',
    fieldNameKey: 'g07',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код региона получателя',
    fieldNameKey: 'g081_region',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Получатель',
    fieldNameKey: 'g082',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Страна получателя',
    fieldNameKey: 'g0831',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Адрес получателя',
    fieldNameKey: 'g083',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Наименование контрактодержателя',
    fieldNameKey: 'g092',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код страны контрактодержателя',
    fieldNameKey: 'g0931',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Адрес контрактодержателя',
    fieldNameKey: 'g093',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код торгующей страны',
    fieldNameKey: 'g11',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Наименование декларанта',
    fieldNameKey: 'g142',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Страна декларанта',
    fieldNameKey: 'g1431',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Адрес декларанта',
    fieldNameKey: 'g143',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код страны отправителя',
    fieldNameKey: 'g0231',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Страна назначения',
    fieldNameKey: 'g17a',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Кол-во транспортных средств при отправлении',
    fieldNameKey: 'g180',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Местонахождение товаров',
    fieldNameKey: 'g300',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код СОАТО получателя',
    fieldNameKey: 'g084b',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код СОАТО контрактодержателя',
    fieldNameKey: 'g094b',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код валюты цены договора',
    fieldNameKey: 'g221',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Общая сумма по счету',
    fieldNameKey: 'g222',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Курс валюты',
    fieldNameKey: 'g23',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код СОАТО отправителя',
    fieldNameKey: 'g024b',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код СОАТО декларанта',
    fieldNameKey: 'g144b',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код таможни на границе',
    fieldNameKey: 'g29',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Товарный знак',
    fieldNameKey: 'g31_12',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код ОКПО банка/паспорт сделки',
    fieldNameKey: 'g281',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код наличия упаковки товара',
    fieldNameKey: 'g31_20',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Количество контейнеров',
    fieldNameKey: 'g31_3',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код единицы измерения, отличной от основной и дополнительной',
    fieldNameKey: 'g31_82',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Преференции',
    fieldNameKey: 'g36',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Процедура',
    fieldNameKey: 'g37',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Вес брутто',
    fieldNameKey: 'g35',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
    format: value => Number(value).toFixed(2),
  },
  {
    fieldName: 'Квота',
    fieldNameKey: 'g39',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Код дополнительной единицы измерения',
    fieldNameKey: 'g41a',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Признак корректировки таможенной стоимости',
    fieldNameKey: 'g43',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Метод определения таможенной стоимости',
    fieldNameKey: 'g430',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Таможенная стоимость',
    fieldNameKey: 'g45',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Предыдущее значение таможенной стоимости',
    fieldNameKey: 'g452',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Цена РУБ за ед изм',
    fieldNameKey: 'rubedizm',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Цена USD за ед изм',
    fieldNameKey: 'usdedizm',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Цена USD за кг',
    fieldNameKey: 'usdkg',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName:
      'Номер Свидетельства о включении в Реестр таможенных брокеров (представителей)',
    fieldNameKey: 'g541',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
  {
    fieldName: 'Общая сумма ТП, подлеж.взысканию/возврату по товару',
    fieldNameKey: 'k470',
    renderingCondition: (hasAccess, value) => hasAccess && !!value,
  },
]
/* eslint-enable react/display-name */
