import { FC, ReactChild } from 'react'
import { NavLink } from 'react-router-dom'
import { Img } from '../img/Img'
import './SidebarMenuItem.scss'

interface SidebarMenuItemProps {
  children: any
  icon?: string
  iconWidth?: number
  iconHeight?: number
  to: string
  isActive?: boolean
  onClick?: () => void
}

export const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  icon,
  iconWidth = 24,
  iconHeight = 24,
  to,
  isActive,
  onClick,
}) => {
  const activeClass = isActive
    ? 'sidebar-menu-item active'
    : 'sidebar-menu-item'

  return (
    <NavLink to={to} className={activeClass} onClick={onClick}>
      {icon && (
        <Img
          className="sidebar-menu-icon"
          width={iconWidth}
          height={iconHeight}
          src={icon}
          alt=""
          aria-hidden="true"
        />
      )}
      {children}
    </NavLink>
  )
}

interface SidebarMenuButtonItemProps {
  children: any
  onClick: () => void
  icon: string
  iconWidth?: number
  iconHeight?: number
  isActive?: boolean
}

export const SidebarMenuButtonItem: FC<SidebarMenuButtonItemProps> = ({
  icon,
  iconWidth = 24,
  iconHeight = 24,
  isActive,
  children,
  onClick,
}) => {
  const activeClass = isActive
    ? 'sidebar-menu-item active'
    : 'sidebar-menu-item'

  return (
    <button className={activeClass} onClick={onClick}>
      <Img
        className="sidebar-menu-icon"
        src={icon}
        width={iconWidth}
        height={iconHeight}
        alt=""
        aria-hidden="true"
      />
      {children}
    </button>
  )
}
