import { createSlice } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../../../redux/configureStore'
import { post } from '../../../../api/api'
import Cookies from 'js-cookie'

const logoutSlice = createSlice({
  name: 'logout',
  initialState: {
    fetching: false,
    error: false,
  },
  reducers: {
    started(state) {
      state.fetching = true
      state.error = false
    },
    succeed(state) {
      state.fetching = false
    },
    failed(state) {
      state.fetching = false
      state.error = true
    },
  },
})

export const logout = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(logoutSlice.actions.started())
  post('/logout')
    .then(() => {
      Cookies.remove('auth_token')
      dispatch(logoutSlice.actions.succeed())
      window.location.href = `${process.env.REACT_APP_LANDING_URL}`
    })
    .catch(() => dispatch(logoutSlice.actions.failed))
}

export default logoutSlice.reducer
