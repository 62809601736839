import React, {FC} from 'react';
import { ButtonPrimary } from '../../ui';
import { Img } from '../../ui/atoms/img/Img'
import './styles.scss'
import { useTranslation } from 'react-i18next'

export const NotFoundPage: FC = () => {
  const { t } = useTranslation()
  return (
    <div className='not-found'>
        <Img src='404.svg' alt="404"/>
        <h1>{t('Похоже, вы заблудились')}</h1>
        <p>{t('Cтраница, которую вы ищете, была перемещена, удалена, переименована или никогда не существовала')}</p>
        <ButtonPrimary onClick={() => window.location.href = `${process.env.REACT_APP_LANDING_URL}`}>{t('На главную')}</ButtonPrimary>
    </div>
  )
}