import { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { contragentSearched } from './contragentCheckSlice'
import { RootState } from '../../redux/configureStore'
import { SearchBar } from '../../features'
import { searchBarStringSet, setIsOpen } from '../../features/search-bar/searchBarSlice'
import { HeroImage } from '../../ui'
import { PageTemplate } from '../../ui/templates'
import '../../styles/spacing.scss'
import './styles.scss'

export const ContragentCheckPage: FC = () => {
  const dispatch = useDispatch()
  
  const contragentState = useSelector(
    (state: RootState) => state.contragentCheck,
  )
  
  // if (contragentState?.searching == null) {
  const value = useSelector((state: RootState) => state.searchBar.value)
  if (!value) {
    const valueFromHash = decodeURIComponent(window.location.hash.slice(1))
    if (valueFromHash) {
      dispatch(searchBarStringSet(valueFromHash))
      // dispatch(contragentSearched(valueFromHash))
      dispatch(setIsOpen(true))
    }
  }

  const searchBarHints = [
    'Shandong Eagle',
    'Beijing Fish',
    'Nep-logistics',
    'China Ocean Shipping',
  ]

  const { t } = useTranslation()
  return (
    <>
      <PageTemplate title={t('Проверка контрагента')}>
        <SearchBar
          icon="icons/search-blue.svg"
          placeholder={t('Введите название компании')}
          dropdownDispatchFn={contragentSearched}
          isSearching={contragentState?.searching}
          dropdownData={contragentState?.data}
          hints={searchBarHints}
        />
      </PageTemplate>
      <HeroImage image="hero.svg" />
    </>
  )
}
