import { IQuestionList, State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, getFile } from '../../api/api'

const initialState: State = {
  data: [],
  fetching: false,
  error: false,
}

const questionListsSlice = createSlice({
  name: 'questionLists',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<IQuestionList[]>) {
      state.data = action.payload
      state.fetching = false
    },
    loadFailed(state) {
      state.fetching = false
      state.error = true
    },
  },
})

export const questionListsLoaded = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(questionListsSlice.actions.loadStarted())
  get('/equipment/questionnaire')
    .then(json => {
      dispatch(questionListsSlice.actions.loadSucceed(json.groups))
    })
    .catch(() => {
      dispatch(questionListsSlice.actions.loadFailed())
    })
}

export const loadFile = (path: string, name: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  console.log('started')
  getFile(path, name)
}

export default questionListsSlice.reducer
