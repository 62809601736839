import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { IAvatar, State } from './types'
import { postFile } from '../../api/api'

const initialState: State = {
  data: {},
  sending: false,
  sendingError: false,
}

export const avatarSlice = createSlice({
  name: 'avatar',
  initialState,
  reducers: {
    sendStarted(state) {
      state.sending = true
    },
    sendSucceed(state, action: PayloadAction<IAvatar>) {
      state.sending = false
      state.data = action.payload
    },
    sendFailed(state) {
      state.sending = false
      state.sendingError = true
    },
  },
})

export const avatarSent = (params: File): AppThunk => (
  dispatch: AppDispatch,
): void => {
  dispatch(avatarSlice.actions.sendStarted())

  const formData = new FormData()
  formData.append('file', params)

  postFile('/photo', formData)
    .then(json => dispatch(avatarSlice.actions.sendSucceed(json)))
    .catch(() => {
      dispatch(avatarSlice.actions.sendFailed())
      alert('Произошла ошибка при загрузке данных')
    })
}

export default avatarSlice.reducer
