import { combineReducers } from '@reduxjs/toolkit'
import dropbox from '../features/dropbox/dropboxSlice'
import connectedAccounts from '../features/connected-accounts/connectedAccountsSlice'
import requests from '../pages/requests/requestsSlice'
import requestDetail from '../pages/request-detail/requestDetailSlice'
import tariffs from '../pages/tariffs/tariffsSlice'
import profile from '../pages/profile/profileSlice'
import contragentCheck from '../pages/contragent-check/contragentCheckSlice'
import contragentInfo from '../pages/contragent-info/contragentInfoSlice'
import equipmentSearch from '../pages/equipment-search/equipmentSearchSlice'
import equipmentItem from '../pages/equipment-item/equipmentItemSlice'
import equipmentComparison from '../pages/equipment-comparison/equipmentComparisonSlice'
import searchBar from '../features/search-bar/searchBarSlice'
import questionLists from '../pages/questions-lists/questionListsSlice'
import avatar from '../features/avatar/avatarSlice'
import logout from '../features/dropdown-menu/molecules/menu-logout-item/menuLogoutItemSlice'
import password from '../features/password/passwordSlice'

export type RootState = ReturnType<typeof rootReducer>

export const rootReducer = combineReducers({
  dropbox,
  connectedAccounts,
  requests,
  requestDetail,
  tariffs,
  profile,
  contragentCheck,
  contragentInfo,
  equipmentSearch,
  questionLists,
  equipmentItem,
  equipmentComparison,
  searchBar,
  avatar,
  logout,
  password,
})
