import {
  ChangeEvent,
  FC,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
} from 'react'
import {
  ButtonPrimary,
  Img,
  SearchHint,
  ErrorMessage,
  IconButton,
} from '../../ui'
import { ButtonResetSearch } from './atoms/ButtonResetSearch/ButtonResetSearch'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { RootState } from '../../redux/configureStore'
import { searchBarStringSet, setIsOpen } from './searchBarSlice'
import { useLocation } from 'react-router-dom'
import { controller } from '../../api/api'
import './SearchBar.scss'
import { useTranslation } from 'react-i18next'

interface SearchBarProps {
  placeholder?: string
  icon?: string
  hints?: string[]
  hasInfoHint?: boolean
  isButton?: boolean
  isMagnifierButton?: boolean
  onClick?: (p: any) => void
  dropdownDispatchFn?: (p: any) => void
  isSearching?: boolean | null
  dropdownData?: any
  dropdownDataType?: string
  resultDispatchFn?: (p: any) => void
  errorMessage?: string
}

export const SearchBar: FC<SearchBarProps> = ({
  icon,
  placeholder,
  hints,
  hasInfoHint = false,
  isButton,
  isMagnifierButton,
  dropdownDispatchFn,
  isSearching = false,
  dropdownData,
  dropdownDataType,
  resultDispatchFn,
  errorMessage,
}) => {
  const value = useSelector((state: RootState) => state.searchBar.value)
  const [debouncedTerm, setDebouncedTerm] = useState(value)
  const [infoHintShown, setInfoHintShown] = useState(false)
  const open = useSelector((state: RootState) => state.searchBar.isOpen)
  const INITIAL_DEBOUNCE = 800
  const [debounce, setDebounce] = useState(INITIAL_DEBOUNCE)
  const ref = useRef<HTMLElement | null>(null)
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  useEffect(() => {
    setDebounce(() => INITIAL_DEBOUNCE)
    const timerId = setTimeout(() => {
      setDebouncedTerm(value)
    }, debounce)

    return () => clearTimeout(timerId)
  }, [value])

  useEffect(() => {
    if (isButton || isMagnifierButton) {
      return
    }

    if (debouncedTerm && dropdownDispatchFn) {
      controller.abort()
      dispatch(dropdownDispatchFn(value))
    }
  }, [dispatch, debouncedTerm, dropdownDispatchFn])

  useLayoutEffect(() => {
    const onBodyClick = (e: any) => {
      if (ref.current && ref.current.contains(e.target)) {
        return
      }
      dispatch(setIsOpen(false))
    }
    document.body.addEventListener('click', onBodyClick, { capture: true })

    return () => document.body.removeEventListener('click', onBodyClick)
  }, [])

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(searchBarStringSet(e.target.value))
    dispatch(setIsOpen(true))

    if (dropdownDataType === 'companies' && resultDispatchFn) {
      dispatch(resultDispatchFn(null))
    }
  }

  const handleSearchHint = (hint: any) => {
    setDebounce(() => 0)
    dispatch(searchBarStringSet(hint.target.innerText))

    if (dropdownDataType === 'equipment') {
      dispatch(
        dropdownDispatchFn && dropdownDispatchFn(hint?.target?.innerText),
      )
    }

    dispatch(setIsOpen(true))
  }

  const handleResultClick = (name: string) => {
    dispatch(setIsOpen(false))
    dispatch(searchBarStringSet(name))

    if (dropdownDataType === 'companies' && resultDispatchFn) {
      const company = dropdownData.find(
        (item: { name: string; inn: string }) => name === item.name,
      )
      dispatch(resultDispatchFn(company))
    }
  }

  const clearSearchBar = () => {
    controller.abort()
    dispatch(searchBarStringSet(''))
    dispatch(setIsOpen(false))

    if (dropdownDataType === 'companies' && resultDispatchFn) {
      dispatch(resultDispatchFn(null))
    }
  }

  const { t } = useTranslation()

  const searchHints = hints ? (
    <div className="mt-16 search-hint-wrapper">
      {hints?.map((hint: string) => (
        <SearchHint key={hint} onClick={handleSearchHint}>
          {t(hint)}
        </SearchHint>
      ))}
    </div>
  ) : (
    ''
  )

  const dropdownSearchResults = isSearching ? (
    <div className="search-results-dropdown">
      <SkeletonList />
    </div>
  ) : dropdownData && dropdownData.length > 0 && debouncedTerm !== '' ? (
    <div className="search-results-dropdown">
      <ul>
        {dropdownDataType === 'companies'
          ? dropdownData.map(
              (item: { name: string; inn: string }): JSX.Element => {
                const { name, inn } = item
                return (
                  <li key={inn}>
                    <div
                      className="search-results-link"
                      onClick={() => handleResultClick(name)}
                    >
                      {name}
                    </div>
                  </li>
                )
              },
            )
          : dropdownData.map(({ id, name }: { id: number; name: string }) => {
              return (
                <li key={id}>
                  <Link
                    to={`/counterparty-check/${id}`}
                    className="search-results-link"
                    onClick={() => handleResultClick(name)}
                  >
                    {name}
                  </Link>
                </li>
              )
            })}
      </ul>
    </div>
  ) : debouncedTerm === '' ? (
    ''
  ) : dropdownData && dropdownData.length === 0 ? (
    <div className="search-results-dropdown">{t('Ничего не найдено')}</div>
  ) : (
    ''
  )

  const showDropdownOnFocus = () => {
    if (dropdownData && dropdownData.length > 0 && debouncedTerm !== '') {
      dispatch(setIsOpen(true))
    }
  }

  const infoHint = useRef<HTMLDivElement>(null)

  const closeOpenMenus = (e: any) => {
    if (
      infoHint.current &&
      infoHintShown &&
      !infoHint.current.contains(e.target)
    ) {
      setInfoHintShown(false)
    }
  }

  document.addEventListener('mousedown', closeOpenMenus)

  return (
    <section className="search-bar-wrapper" ref={ref}>
      <form
        className="search-bar"
        onSubmit={e => {
          e.preventDefault()
        }}
      >
        {icon && (
          <Img
            className="search-bar__icon"
            src={icon}
            alt=""
            aria-hidden="true"
          />
        )}
        <input
          className="search-bar__input"
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          onFocus={showDropdownOnFocus}
          value={value}
          aria-label="searchbar"
        />
        {value && <ButtonResetSearch onClick={clearSearchBar} />}
        {hasInfoHint && (
          <div className="search-bar__hint-btn" ref={infoHint}>
            <IconButton
              onClick={() => {
                if (!infoHintShown) {
                  setInfoHintShown(true)
                }
              }}
              icon="icons/info.svg"
              buttonType="button"
            />
            <div
              className={`search-bar__info-hint${
                infoHintShown ? ' search-bar__info-hint--visible' : ''
              }`}
            >
              Если вы не знаете название оборудования или сомневаетесь –
              напишите в чат поддержки, мы вам поможем.
            </div>
          </div>
        )}
        {isButton && (
          <div className="search-bar__btn">
            <ButtonPrimary
              onClick={() => {
                if (value === '') {
                  return
                }
                dispatch(dropdownDispatchFn && dropdownDispatchFn(value))
              }}
            >
              {t('Найти')}
            </ButtonPrimary>
          </div>
        )}
        {isMagnifierButton && (
          <div className="search-bar__magnifier-btn">
            <IconButton
              onClick={() => {
                if (value === '') {
                  return
                }
                dispatch(dropdownDispatchFn && dropdownDispatchFn(value))
              }}
              icon="icons/search-blue.svg"
            />
          </div>
        )}
      </form>
      {open && dropdownSearchResults}
      <ErrorMessage message={errorMessage} />
      {searchHints}
    </section>
  )
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="questions-lists-skeleton search-skeleton">
        <Skeleton count={8} height={20} />
      </div>
    </SkeletonTheme>
  )
}
