import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/configureStore'
import { SidebarBox, Logo, LinkImage, Hamburger } from '../../atoms'
import { DropdownMenu } from '../../../features'
// import { langList } from '../../../config/constants'
import './Sidebar.scss'
import { useTranslation } from 'react-i18next'

export const Sidebar = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const landingUrl = `${process.env.REACT_APP_LANDING_URL}`
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`
  const profile = useSelector((state: RootState) => state.profile)
  const avatarUrl = profile?.data?.avatar_link

  const renderAvatar = !avatarUrl
    ? `${process.env.PUBLIC_URL}/icons/avatar-empty.svg`
    : `${baseUrl}${avatarUrl}`
  
  // const langCode = langList.find((x: any) => x.id == profile.data.language)?.code
  const { t, i18n } = useTranslation()

  return (
    <SidebarBox>
      <header className="sidebar-header">
        <a href={landingUrl}>
          <Logo
            desktopImage={`company-logo-desktop-${i18n.language}.svg`}
            mobileImage="company-logo-mobile"
          />
        </a>
        <LinkImage image={renderAvatar} linkto="/profile" linkTitle="Profile" />
        <Hamburger setIsOpen={setIsOpen} isOpen={isOpen} />
      </header>
      <DropdownMenu isOpen={isOpen} setIsOpen={setIsOpen} />
    </SidebarBox>
  )
}
