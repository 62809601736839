import { FC, useState } from 'react'
import { Box, Img } from '../../atoms'
import './Accordion.scss'

interface AccordionProps {
  children: React.ReactNode
  title: string | React.ReactNode
  arrowColor: string
  padding: string
  fixOpened?: boolean
}

export const Accordion: FC<AccordionProps> = ({
  children,
  title,
  arrowColor,
  padding,
  fixOpened,
}) => {
  const [opened, setOpened] = useState<boolean>(false)
  const onClick = () => {
    if (!fixOpened) {
      setOpened(!opened)
    }
  }

  const accordionOpened = opened
    ? 'accordion-content--opened'
    : 'accordion-content--closed'

  return (
    <Box color="white" padding={padding} onClick={onClick}>
      <div className="accordion">
        <div className="accordion-title">{title}</div>
        <div className="accordion-button">
          {!fixOpened && (
            <button onClick={onClick}>
              <Img
                src={
                  opened
                    ? `icons/dropdown-up-${arrowColor}.svg`
                    : `icons/dropdown-${arrowColor}.svg`
                }
                alt=""
              />
            </button>
          )}
        </div>
      </div>
      <div
        className={fixOpened ? 'accordion-content--opened' : accordionOpened}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </div>
    </Box>
  )
}
