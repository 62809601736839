import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'
import {
  ButtonPrimary,
  H1,
  Input,
  Status,
  Box,
  Modal,
  EmptyResultMessage,
} from '../../ui'
import { Dropbox } from '../../features'
import { requestCreated, requestsLoaded } from './requestsSlice'
import { RootState } from '../../redux/configureStore'
import { Params, RequestSectionProps, RequestsItemProps } from './types'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './styles.scss'
import { useTranslation } from 'react-i18next'

export const RequestsPage: FC = () => {
  const dispatch = useDispatch()
  const requestsList = useSelector((state: RootState) => state.requests.data)
  const fetching = useSelector((state: RootState) => state.requests.fetching)

  const profile = useSelector((state: RootState) => state.profile.data)

  const finishedRequests = requestsList.filter(
    item => item.status === 'completed' || item.status === 'canceled',
  )

  const inProgressRequests = requestsList.filter(
    item =>
      item.status === 'new' ||
      item.status === 'pending' ||
      item.status === 'in_progress',
  )

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    dispatch(requestsLoaded())
  }, [dispatch])

  const { t } = useTranslation()
  return (
    <>
      <div className="requests-page-header">
        <H1>{t('Заявки')}</H1>
        {profile.user_type !== 'specialist' && (
          <ButtonPrimary onClick={() => setModalOpen(!modalOpen)}>
            {t('Создать заявку')}
          </ButtonPrimary>
        )}
      </div>
      {fetching ? (
        <SkeletonList />
      ) : (
        <>
          {requestsList.length === 0 && (
            <EmptyResultMessage>
              {t('У вас пока нет ни одной заявки.')}
            </EmptyResultMessage>
          )}
          <RequestsSection title={t('В работе')} requestsList={inProgressRequests} />
          <RequestsSection
            title={t('Завершённые заявки')}
            requestsList={finishedRequests}
          />
        </>
      )}
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={t('Создать заявку')}
      >
        <ModalContent setModalOpen={setModalOpen} modalOpen={modalOpen} />
      </Modal>
    </>
  )
}

interface ModalProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalOpen: boolean
}

export const ModalContent: FC<ModalProps> = ({ setModalOpen }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const files = useSelector((state: RootState) => state.dropbox.files)
  const sending = useSelector((state: RootState) => state.dropbox.sending)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Это обязательное поле')),
  })

  const { register, errors, handleSubmit } = useForm<Params>({
    resolver: yupResolver(validationSchema),
  })

  const filesID: number[] = files.map(item => item.id)

  const createRequest = (data: Params) => {
    dispatch(requestCreated({ ...data, files: filesID }))
    setModalOpen(false)
  }

  return (
    <>
      <form action="#!" className="modal-form">
        <Input
          type="text"
          name="name"
          placeholder={t('Название заявки')}
          register={register}
          error={errors.name}
        />
        <p className="modal-form__description mt-24">
          {t('Приложите к заявке')}
          <label>
            <input type="file" style={{ display: 'none' }} />
            &nbsp;
            <Link to="/questionnaires" target="_blank">
              <span className="modal-form__upload-link">{t('опросный лист')}</span>
            </Link>
            &nbsp;
          </label>
          {t('и другие документы, которые помогут нам подобрать оборудование')}
        </p>
      </form>
      <Dropbox />
      <div className="mt-32 modal-form__btn">
        <ButtonPrimary
          isDisabled={sending}
          onClick={handleSubmit(createRequest)}
        >
          {t('Создать')}
        </ButtonPrimary>
      </div>
    </>
  )
}

const RequestsItem: FC<RequestsItemProps> = ({ item }) => {
  const history = useHistory()
  const handleClick = () => history.push(`requests/${item.id}`)

  return (
    <Box color="white" padding="p-12-16">
      <div className="request-item" onClick={handleClick}>
        <div className="request-item-info">
          <div className="request-item-id">#{item.id}</div>
          <Status status={item.status} />
        </div>
        <div>{item.name}</div>
      </div>
    </Box>
  )
}

const RequestsSection: FC<RequestSectionProps> = ({ requestsList, title }) => {
  if (requestsList.length === 0) {
    return null
  }

  return (
    <section className="mb-32">
      <h4 className="request-section-title">{title}</h4>
      {requestsList.map(item => (
        <RequestsItem item={item} key={item.id} />
      ))}
    </section>
  )
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="requests-page-skeleton-header">
        <Skeleton width={70} height={18} />
      </div>
      <div className="requests-page-skeleton-list">
        <Skeleton count={10} height={59} />
      </div>
    </SkeletonTheme>
  )
}
