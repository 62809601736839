import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { PasswordParams } from './types'
import { passwordChanged } from './passwordSlice'
import { Box, ButtonPrimary, H3, Input } from '../../ui'
import { RootState } from '../../redux/configureStore'
import { useTranslation } from 'react-i18next'

export const Password: FC = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  
  const errorMessage = useSelector(
    (state: RootState) => state.password.errorMessage,
  )
  const fetching = useSelector((state: RootState) => state.password.fetching)
  const success = useSelector((state: RootState) => state.password.success)

  const validationSchema = Yup.object().shape({
    old_password: Yup.string().required(t('Это обязательное поле')),
    new_password: Yup.string()
      .required(t('Это обязательное поле'))
      .min(8, t('Убедитесь, что это значение содержит не менее 8 символов.')),
    confirm_new_password: Yup.string()
      .oneOf([Yup.ref('new_password'), null], t('Пароли не совпадают'))
      .required(t('Это обязательное поле')),
  })

  const { register, handleSubmit, errors, setValue } = useForm<PasswordParams>({
    resolver: yupResolver(validationSchema),
  })

  const editPassword = (data: PasswordParams) => {
    dispatch(passwordChanged(data))
  }

  if (success && !fetching) {
    setValue('old_password', '')
    setValue('new_password', '')
    setValue('confirm_new_password', '')
  }

  return (
    <Box color="white" padding="p-16-24">
      <div className="mb-16">
        <H3>{t('Безопасность')}</H3>
      </div>
      <form>
        <Input
          title={t('Старый пароль')}
          name="old_password"
          register={register}
          error={errors.old_password || errorMessage.old_password}
          errorMessage={errorMessage.old_password}
          type="password"
        />
        <Input
          title={t('Новый пароль')}
          name="new_password"
          error={errors.new_password || errorMessage.new_password}
          errorMessage={errorMessage.new_password}
          register={register}
          type="password"
        />
        <Input
          title={t('Повторите новый пароль')}
          name="confirm_new_password"
          error={errors.confirm_new_password}
          register={register}
          type="password"
        />
        <div className="mt-8">
          <ButtonPrimary onClick={handleSubmit(editPassword)}>
            {t('Сохранить')}
          </ButtonPrimary>
        </div>
      </form>
    </Box>
  )
}
