import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ContragentItem, State } from './types'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get } from '../../api/api'

const initialState: State = {
  data: [],
  searching: null,
  error: null,
}

const contragentCheckSlice = createSlice({
  name: 'contragentCheck',
  initialState,
  reducers: {
    searchStarted(state) {
      state.data = []
      state.searching = true
      state.error = null
    },
    searchSucceed(state, action: PayloadAction<ContragentItem[]>) {
      state.data = action.payload
      state.searching = false
      state.error = null
    },
    searchFailed(state, action: PayloadAction<string>) {
      state.data = []
      state.searching = false
      state.error = action.payload
    },
  },
})

export const contragentSearched = (search: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(contragentCheckSlice.actions.searchStarted())
  get(`/counterparty/search/${search}`)
    .then(json => {
      dispatch(contragentCheckSlice.actions.searchSucceed(json.data))
    })
    .catch(err => console.log(err))
}

export default contragentCheckSlice.reducer
