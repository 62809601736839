import { FC, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fileRemoved, filesSent } from './dropboxSlice'
import { RootState } from '../../redux/configureStore'
import { File } from './atoms/file/File'
import './Dropbox.scss'
import { useTranslation } from 'react-i18next'

export const Dropbox: FC = ({ children }) => {
  const dispatch = useDispatch()
  const files = useSelector((state: RootState) => state.dropbox.files)
  const sending = useSelector((state: RootState) => state.dropbox.sending)

  const input = useRef<HTMLInputElement>(null)

  const onFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch(filesSent(e.target.files[0]))
    }
  }

  const onFileRemove = (id: number) => {
    dispatch(fileRemoved(id))
  }

  const onDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()

    const dt = e.dataTransfer
    dispatch(filesSent(dt.files[0]))
  }

  const onDialogOpen = (e: React.SyntheticEvent) => {
    e.preventDefault()
    input.current && input.current.click()
  }

  const onDragenter = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onDragover = (e: React.DragEvent<HTMLDivElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const { t } = useTranslation()
  return (
    <section
      onDragEnter={onDragenter}
      onDragOver={onDragover}
      onDrop={onDrop}
      className="dropbox"
    >
      <div className="dropbox-field">
        <div>
          {sending ? (
            t('Загрузка...')
          ) : (
            <>
              <input
                type="file"
                name="file"
                ref={input}
                onChange={onFilesChange}
              />
              {t('Перетащите сюда файл для загрузки или')}{' '}
              <button className="dropbox-button" onClick={onDialogOpen}>
                {' '}
                {t('выберите его вручную')}
              </button>
            </>
          )}
        </div>
      </div>
      <div
        className={
          files.length !== 0 ? 'dropbox-files-list' : 'dropbox-files-list-empty'
        }
      >
        {files.length !== 0 &&
          files.map(file => (
            <File
              name={file.name}
              key={file.id}
              onRemove={() => onFileRemove(file.id)}
            />
          ))}
        {children}
      </div>
    </section>
  )
}
