import { ProfileParams, ILangField, State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, put } from '../../api/api'
import { langList } from '../../config/constants'

const initialState: State = {
  data: {},
  fetching: false,
  error: false,
  editing: false,
  editingError: false,
  langEditing: false,
  langEditingError: false,
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<object>) {
      state.data = action.payload
      state.fetching = false
    },
    loadFailed(state) {
      state.error = true
      state.fetching = false
    },
    editStarted(state) {
      state.editing = true
    },
    editSucceed(state, action) {
      state.editing = false
      state.data.avatar_link = action.payload.avatar_link
      state.data.name = action.payload.params.name
      state.data.email = action.payload.params.email
      state.data.phone = action.payload.params.phone
      state.data.position = action.payload.params.position
      // state.data.language = action.payload.params.language
    },
    editFailed(state) {
      state.editing = false
      state.editingError = true
    },
    langEditStarted(state) {
      state.langEditing = true
    },
    langEditSucceed(state, action) {
      state.langEditing = false
      state.data.language = action.payload.params.language
    },
    langEditFailed(state) {
      state.langEditing = false
      state.langEditingError = true
    },
  },
})

export const profileLoaded = (i18n?: any): AppThunk => (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.loadStarted())
  get('/profile')
    .then(json => {
      dispatch(profileSlice.actions.loadSucceed(json))
      if (typeof i18n !== 'undefined') {
        i18n.changeLanguage(langList.find((x: any) => x.id == json.language).code)
      }
    })
    .catch(() => {
      dispatch(profileSlice.actions.loadFailed())
    })
}

export const profileEdited = (
  params: ProfileParams,
  avatar_link: string,
  i18n?: any,
): AppThunk => (dispatch: AppDispatch) => {
  dispatch(profileSlice.actions.editStarted())
  put('/profile', params)
    .then(() => {
      dispatch(profileSlice.actions.editSucceed({ params, avatar_link }))
      // if (typeof i18n !== 'undefined') {
        // i18n.changeLanguage(langList.find((x: any) => x.id == params.language).code)
      // }
    })
    .catch(() => {
      dispatch(profileSlice.actions.editFailed())
    })
}

export const languageEdited = (
  params: ILangField,
  i18n?: any,
): AppThunk => (dispatch: AppDispatch) => {
  const code = langList.find((x: any) => x.id == params.language).code
  dispatch(profileSlice.actions.langEditStarted())
  put('/language', {'language': code})
    .then(() => {
      if (typeof i18n !== 'undefined') {
        i18n.changeLanguage(code)
      }
      dispatch(profileSlice.actions.langEditSucceed({ params }))
    })
    .catch(() => {
      dispatch(profileSlice.actions.langEditFailed())
    })
}

export default profileSlice.reducer
