import { FC, useState } from 'react'
import { Img, Status } from '../../index'
import './StatusDropdown.scss'

interface StatusDropdownProps {
  changeStatus: any
}

export const StatusDropdown: FC<StatusDropdownProps> = ({ changeStatus }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      <div>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="status-dropdown-button"
        >
          <Img alt="" src="icons/dropdown-blue.svg" />
        </button>
      </div>
      <div
        className={isOpen ? 'status-dropdown opened' : 'status-dropdown closed'}
      >
        <div
          className="status-dropdown-item"
          onClick={() => {
            changeStatus({ status: 'in_progress' })
            setIsOpen(!isOpen)
          }}
        >
          <Status status="in_progress" />
        </div>
        <div
          className="status-dropdown-item"
          onClick={() => {
            changeStatus({ status: 'pending' })
            setIsOpen(!isOpen)
          }}
        >
          <Status status="pending" />
        </div>
      </div>
    </>
  )
}
