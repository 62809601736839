import { FC } from 'react'
import { H1 } from '../../atoms'
import './PageTemplate.scss'

interface PageTemplateProps {
  children: React.ReactNode
  title: string
}

export const PageTemplate: FC<PageTemplateProps> = ({ children, title }) => {
  return (
    <>
      <H1>{title}</H1>
      <div className="mt-16-32">{children}</div>
    </>
  )
}
