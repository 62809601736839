import { FC } from 'react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { SidebarMenuItem } from '../../../../ui'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/configureStore'
import './MenuProfileItem.scss'

export const MenuProfileItem: FC = () => {
  const profile = useSelector((state: RootState) => state.profile.data)

  const baseUrl = `${process.env.REACT_APP_BASE_URL}`

  const avatar =
    profile && profile.avatar_link && `${baseUrl}/${profile.avatar_link}`

  if (!profile?.name) {
    return (
      <SidebarMenuItem to="/profile">
        <SkeletonTheme color="#E6EAF1">
          <div className="menu-profile-item-skeleton">
            <Skeleton height={24} width={24} />
            <Skeleton width={100} />
          </div>
        </SkeletonTheme>
      </SidebarMenuItem>
    )
  }

  return (
    <SidebarMenuItem to="/profile">
      <img
        src={avatar || process.env.PUBLIC_URL + '/icons/avatar-empty.svg'}
        className="sidebar-menu-icon sidebar-menu-icon--avatar"
        alt=""
      />
      {profile.name}
    </SidebarMenuItem>
  )
}
