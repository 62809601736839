import { FC } from 'react'
import './ErrorMessage.scss'

interface ErrorMessageProps {
  message?: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return <p className="error-message">{message}</p>
}
