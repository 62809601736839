import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import smoothscroll from 'smoothscroll-polyfill'
import i18n from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { Sidebar, MainBox, ContentBox } from './ui'
import { Routes } from './routes'
import { profileLoaded } from './pages/profile/profileSlice'
import { enLocale } from './locale/en'
import { zhLocale } from './locale/zh'

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {translation: enLocale},
      zh: {translation: zhLocale},
    },
    // lng: 'ru',
    fallbackLng: 'ru',

    interpolation: {
      escapeValue: false
    }
  })

export const App: FC = (): JSX.Element => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  useEffect(() => {
    dispatch(profileLoaded(i18n))
  }, [dispatch])

  smoothscroll.polyfill()

  return (
    <MainBox>
      <Sidebar />
      <ContentBox>
        <Routes />
      </ContentBox>
    </MainBox>
  )
}
