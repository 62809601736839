import { createSlice } from '@reduxjs/toolkit'

const searchBarSlice = createSlice({
  name: 'searchBar',
  initialState: {
    value: '',
    isOpen: false,
  },
  reducers: {
    searchBarStringSet(state, action) {
      state.value = action.payload
    },
    setIsOpen(state, action) {
      state.isOpen = action.payload
    },
  },
})

export const { searchBarStringSet, setIsOpen } = searchBarSlice.actions

export default searchBarSlice.reducer
