import { FC, forwardRef } from 'react'
import './Boxes.scss'

interface BoxProps {
  children: React.ReactNode
  color: string
  padding?: string
  onClick?: () => void
  isStatic?: boolean
}

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ children, color, padding = '', onClick, isStatic }, ref) => {
    const className = isStatic
      ? `${color}-box ${padding} static`
      : `${color}-box ${padding}`

    return (
      <section className={className} onClick={onClick} ref={ref || undefined}>
        {children}
      </section>
    )
  },
)
Box.displayName = 'Box'

interface ConnectedBoxProps {
  children: React.ReactNode
  color: string
}

export const ConnectedBox: FC<ConnectedBoxProps> = ({ children, color }) => {
  return <section className={`${color}-connected-box`}>{children}</section>
}
