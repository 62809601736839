import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { put } from '../../api/api'
import { IErrorMessage, PasswordParams, State } from './types'

const initialState: State = {
  fetching: false,
  error: false,
  errorMessage: {},
  success: false,
}

const passwordSlice = createSlice({
  name: 'password',
  initialState,
  reducers: {
    changeStarted(state) {
      state.fetching = true
      state.error = false
      state.success = false
      state.errorMessage = {}
    },
    changeSucceed(state) {
      state.fetching = false
      state.success = true
    },
    changeFailed(state, action: PayloadAction<IErrorMessage>) {
      state.fetching = false
      state.error = true
      state.errorMessage = action.payload
    },
    setInitialState(state) {
      state.fetching = false
      state.error = false
      state.errorMessage = {}
      state.success = false
    },
  },
})

export const passwordChanged = (params: PasswordParams): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(passwordSlice.actions.changeStarted())
  put('./password', params)
    .then(() => {
      dispatch(passwordSlice.actions.changeSucceed())
      dispatch(passwordSlice.actions.setInitialState())
    })
    .catch(err => dispatch(passwordSlice.actions.changeFailed(err)))
}

export default passwordSlice.reducer
