import { FC } from 'react'
import { Img } from '../img/Img'
import './Logo.scss'

interface LogoProps {
  desktopImage: string
  mobileImage: string
}

export const Logo: FC<LogoProps> = ({ desktopImage, mobileImage }) => {
  return (
    <figure>
      <Img src={desktopImage} alt="Company" className="desktop-image logo" />
      <Img src={mobileImage} alt="Company" className="mobile-image logo" />
    </figure>
  )
}
