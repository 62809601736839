import { FC } from 'react'
import './Input.scss'
import { ErrorMessage } from '../../atoms/error-message/ErrorMessage'

interface InputProps {
  placeholder?: string
  title?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  register?: any
  type?: string
  disabled?: boolean
  error?: any
  errorMessage?: string
}

export const Input: FC<InputProps> = ({
  placeholder,
  title,
  value,
  name,
  onChange,
  type,
  register,
  disabled,
  error,
  errorMessage,
}) => {
  const className = error ? 'input error' : 'input'

  return (
    <div>
      <p className="input-title">{title}</p>
      <input
        className={className}
        type={type || ''}
        name={name}
        ref={register}
        value={value}
        onChange={onChange}
        disabled={disabled}
        placeholder={placeholder}
      />
      <ErrorMessage message={error?.message || errorMessage} />
    </div>
  )
}
