import { FC } from 'react'
import { Img } from '../../../../ui'
import './ButtonResetSearch.scss'

interface ResetProps {
  onClick?: () => void
}

export const ButtonResetSearch: FC<ResetProps> = ({ onClick }) => {
  return (
    <>
      <span
        className="reset-search"
        onClick={onClick}
        aria-label="Clear searchbar"
      >
        <Img src="icons/close-grey.svg" aria-hidden="true" alt="" />
      </span>
    </>
  )
}
