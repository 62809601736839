import { FC, ReactChild } from 'react'
import { Img } from '../img/Img'
import './Buttons.scss'

interface ButtonProps {
  children?: ReactChild | React.ReactNode
  isDisabled?: boolean
  icon?: string
  type?: string
  buttonType?: 'button' | 'submit' | 'reset' | undefined
  onClick?: () => void
  download?: boolean
}

export const ButtonPrimary: FC<ButtonProps> = ({
  children,
  isDisabled,
  icon,
  onClick,
}) => {
  const haveIcon = icon ? <Img src={icon} alt="" aria-hidden="true" /> : ''
  return (
    <button className="button-primary" onClick={onClick} disabled={isDisabled}>
      {haveIcon}
      {children}
    </button>
  )
}

export const ButtonSecondary: FC<ButtonProps> = ({
  children,
  isDisabled,
  icon,
}) => {
  const haveIcon = icon ? <Img src={icon} alt="" aria-hidden="true" /> : ''
  return (
    <button className="button-secondary" disabled={isDisabled}>
      {haveIcon}
      {children}
    </button>
  )
}
export const LinkButton: FC<ButtonProps> = ({
  children,
  icon,
  type,
  download,
  onClick,
}) => {
  const isIcon = icon ? (
    <Img className="link-button-icon" src={icon} alt="" aria-hidden="true" />
  ) : (
    ''
  )

  return (
    <a className={`link-button-${type}`} onClick={onClick} {...download}>
      {isIcon}
      {children}
    </a>
  )
}

export const IconButton: FC<ButtonProps> = ({
  icon,
  type,
  buttonType,
  onClick,
}) => {
  const haveIcon = icon ? <Img src={icon} alt="" aria-hidden="true" /> : ''
  return (
    <button
      type={buttonType}
      className={type === 'round' ? 'icon-button-round' : 'icon-button'}
      onClick={onClick}
    >
      {haveIcon}
    </button>
  )
}
