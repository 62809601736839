import { FC } from 'react'

interface ImgProps {
  src?: string
  width?: number
  height?: number
  alt: string
  className?: string
  onClick?: () => void
}

export const Img: FC<ImgProps> = ({
  src,
  width,
  height,
  alt,
  className,
  onClick,
}) => {
  return (
    <img
      src={`${process.env.PUBLIC_URL}/${src}`}
      alt={alt}
      className={className}
      style={{
        width: width,
        height: height,
      }}
      onClick={onClick}
    />
  )
}
