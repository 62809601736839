import { FC } from 'react'
import { Link } from 'react-router-dom'
import './LinkImage.scss'
import '../../../styles/utilities.scss'

interface LinkImageProps {
  image: string
  linkto: string
  linkTitle?: string
}

export const LinkImage: FC<LinkImageProps> = ({ image, linkto, linkTitle }) => {
  return (
    <Link to={linkto} title={linkTitle} className="link-image mobile-only">
      <img src={image} className="link-image__img" alt="" aria-hidden="true" />
    </Link>
  )
}
