import { FC, useState, useEffect, useRef } from 'react'
import { SearchBar } from '../../features'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import { equipmentSearched, loadEquipments } from './equipmentSearchSlice'
import { HeroImage, Box } from '../../ui'
import { PageTemplate } from '../../ui/templates'
import { Link } from 'react-router-dom'
import { Img } from '../../ui'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import '../../styles/spacing.scss'
import '../../styles/utilities.scss'
import './styles.scss'
import { useTranslation } from 'react-i18next'

export const EquipmentSearchPage: FC = () => {
  const equipmentState = useSelector(
    (state: RootState) => state.equipmentSearch,
  )
  // const { data, error }: any = equipmentState?.data
  const error = '' // -_-
  const data = equipmentState.data
  const isSearching = equipmentState.searching
  const wasSearched = equipmentState.wasSearched
  const searchedTerm = equipmentState.searchedTerm
  const [isMobile, setIsMobile] = useState(false)
  const dispatch = useDispatch()

  const scrollToRef = useRef<any>()

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true)
      renderResponsiveSearchBar()
    }
  }, [])

  const renderData = () => {
    if (!isSearching && !data.length && !wasSearched && !error) {
      return <HeroImage image="hero-2.svg" />
    }
    if ((wasSearched && data && data.length === 0) || error) {
      return <EquipmentNotFound error={error} />
    }
    if (isSearching) {
      return <SkeletonList height={480} />
    } else if (data && data.length > 0) {
      return <EquipmentResults data={data} />
    }
  }

  const { t } = useTranslation()

  const renderResponsiveSearchBar = () =>
    isMobile ? (
      <SearchBar
        isMagnifierButton
        placeholder={t('Введите название оборудования')}
        dropdownDispatchFn={equipmentSearched}
        dropdownDataType="equipment"
        hints={['Теплообменник', 'Реактор', 'Гранулятор', 'Фильтр']}
      />
    ) : (
      <SearchBar
        icon="icons/search-blue.svg"
        hasInfoHint
        isButton
        placeholder={t('Введите название оборудования')}
        dropdownDispatchFn={equipmentSearched}
        dropdownDataType="equipment"
        hints={['Теплообменник', 'Реактор', 'Гранулятор', 'Фильтр']}
      />
    )

  return (
    <section>
      <PageTemplate title={t('Поиск оборудования')}>
        {renderResponsiveSearchBar()}
      </PageTemplate>
      <div ref={scrollToRef}></div>
      {renderData()}
      <div className="pb-16"></div>
    </section>
  )
}

interface EquipmentResultsProps {
  data: any
}

const EquipmentResults: FC<EquipmentResultsProps> = ({ data }) => {
  const equipmentState = useSelector(
    (state: RootState) => state.equipmentSearch,
  )
  const value = useSelector((state: RootState) => state.searchBar.value)
  const currentPage = equipmentState.currentPage
  let pageCutLow = currentPage - 1
  let pageCutHigh = currentPage + 1
  const numberOfPages = equipmentState.numberOfPages
  const dispatch = useDispatch()

  let pageNumbers: any[] = []

  if (numberOfPages > 5) {
    if (currentPage > 2) {
      pageNumbers.push(1)
      if (currentPage > 3) {
        pageNumbers.push('...1')
      }
    }
    if (currentPage === 1) {
      pageCutHigh += 2
    } else if (currentPage === 2) {
      pageCutHigh += 1
    }
    if (currentPage === numberOfPages) {
      pageCutLow -= 2
    } else if (currentPage === numberOfPages - 1) {
      pageCutLow -= 1
    }
    for (let p = pageCutLow; p <= pageCutHigh; p++) {
      if (p === 0 || p > numberOfPages) {
        continue
      }
      pageNumbers.push(p)
    }
    if (currentPage < numberOfPages - 1) {
      if (currentPage < numberOfPages - 2) {
        pageNumbers.push('...2')
      }
      pageNumbers.push(numberOfPages)
    }
  } else {
    pageNumbers = Array.from(Array(numberOfPages + 1).keys()).slice(1)
  }

  const loadPage = (page: number) => {
    // if(searchedTerm && searchedTerm !== value){
    dispatch(loadEquipments(value, page))
  }

  return (
    <>
      <div className="mt-16 box-anim">
        <Box color="white" padding="p-16">
          <section className="content-wrapper">
            {data.map((equip: any) => (
              <div className="eq-result" key={equip.id}>
                <Link
                  to={{
                    pathname: `/equipment/${equip.id}`,
                    state: { cameFromSearch: true },
                  }}
                  className="eq-result__link"
                >
                  {`${equip.name.slice(0, 200)}...`}
                </Link>
                <p className="eq-result__company">{equip.seller}</p>
              </div>
            ))}
            {numberOfPages > 1 && (
              <div className="pagination">
                {currentPage > 1 && (
                  <button
                    className="pagination__previous-button"
                    onClick={() => loadPage(currentPage - 1)}
                  >
                    <Img
                      className="pagination__icon-previous"
                      src="icons/arrow-right.svg"
                      alt=""
                    />
                  </button>
                )}
                <div className="pagination__items">
                  {pageNumbers.map(item => (
                    <button
                      className={
                        'pagination__' +
                        (typeof item === 'string' ? 'ellipsis' : 'item') +
                        (item === currentPage
                          ? ' pagination__item--active'
                          : '')
                      }
                      key={item}
                      disabled={typeof item === 'string'}
                      onClick={() => loadPage(+item)}
                    >
                      {typeof item === 'string' ? item.slice(0, -1) : item}
                    </button>
                  ))}
                </div>
                {currentPage < numberOfPages && (
                  <button
                    className="pagination__next-button"
                    onClick={() => loadPage(currentPage + 1)}
                  >
                    <Img
                      className="pagination__icon-next"
                      src="icons/arrow-right.svg"
                      alt=""
                    />
                  </button>
                )}
              </div>
            )}
          </section>
        </Box>
      </div>
    </>
  )
}

interface NotFoundProps {
  error?: string
}

const EquipmentNotFound: FC<NotFoundProps> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <div className="mt-16 box-anim">
      <Box color="white" padding="p-16">
        <div className="not-found">
          {error === 'too_many_results' &&
            `Найдено слишком много записей. Пожалуйста, конкретизируйте запрос.`}
          {error === 'unavailable' && 'Сервис временно недоступен.'}
          {!error &&
            'По вашему запросу не было найдено результатов. Попробуйте конкретизировать поисковый запрос.'}
        </div>
      </Box>
    </div>
  )
}

const SkeletonList: FC<{ height: number }> = ({ height }) => {
  return (
    <div className="mt-16 box-anim">
      <SkeletonTheme color="#E6EAF1">
        <div className="questions-lists-skeleton">
          <Skeleton count={1} height={height} />
        </div>
      </SkeletonTheme>
    </div>
  )
}
