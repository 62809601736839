import { FC } from 'react'
import { useLocation } from 'react-router-dom'
import './ContentBox.scss'

interface ContentBoxProps {
  children: React.ReactNode
}

export const ContentBox: FC<ContentBoxProps> = ({ children }) => {
  const location = useLocation()

  return (
    <main
      className={`content-box${
        location.pathname === '/equipment/comparison'
          ? ' content-box--full-width'
          : ''
      }`}
    >
      {children}
    </main>
  )
}
