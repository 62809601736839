import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { Box, Input, ListItem, ButtonPrimary, PageTemplate, H3, Select } from '../../ui'
import { Avatar, ConnectedAccounts, Password } from '../../features'
import {
  AvatarController,
  IFields,
  ILangField,
  OrganisationSectionProps,
  ProfileParams,
} from './types'
import { RootState } from '../../redux/configureStore'
import { profileEdited, profileLoaded, languageEdited } from './profileSlice'
import { IAvatar } from '../../features/avatar/types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './styles.scss'
import { langList } from '../../config/constants'
import { useTranslation } from 'react-i18next'

export const ProfilePage: FC = () => {
  const dispatch = useDispatch()

  const profile = useSelector((state: RootState) => state.profile.data)
  const fetching = useSelector((state: RootState) => state.profile.fetching)

  const [avatar, setAvatar] = useState<AvatarController>({
    id: null,
    link: '',
  })
  const newAvatar: IAvatar = useSelector(
    (state: RootState) => state.avatar.data,
  )

  const { t, i18n } = useTranslation()
  
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Это обязательное поле')),
    email: Yup.string()
      .required(t('Это обязательное поле'))
      .email(t('Введен некорректный email')),
  })

  const { register, handleSubmit, setValue, errors } = useForm<IFields>({
    resolver: yupResolver(validationSchema),
  })

  const {
    register: langRegister,
    handleSubmit: handleLangSubmit,
    setValue: setLangValue,
    errors: langErrors
  } = useForm<ILangField>({
    resolver: yupResolver(Yup.object().shape({})),
  })

  useEffect(() => {
    setValue('name', profile.name)
    setValue('email', profile.email)
    setValue('phone', profile.phone)
    setValue('position', profile.position)
    setLangValue('language', profile.language)
    if (profile.avatar) {
      setAvatar({ id: profile.avatar, link: profile.avatar_link })
    }
  }, [profile, setValue, setLangValue])

  useEffect(() => {
    if (newAvatar.id) {
      setAvatar({ id: newAvatar.id, link: newAvatar.link })
    }
  }, [newAvatar])

  const onAvatarRemove = () => {
    setAvatar({ id: null, link: '' })
  }

  const editProfile = (data: ProfileParams) => {
    dispatch(profileEdited({ ...data, avatar: avatar.id }, avatar.link, i18n))
  }
  
  const editLanguage = (data: ILangField) => {
    dispatch(languageEdited(data, i18n))
  }

  return (
    <PageTemplate title={t('Профиль')}>
      {fetching ? (
        <SkeletonList />
      ) : (
        <>
          <form>
            <Box color="white" padding="p-16-24">
              <div className="mb-16">
                <H3>{t('Персональные данные')}</H3>
              </div>
              <Avatar image={avatar.link} onRemove={onAvatarRemove} />
              <Input
                title={t('Имя')}
                name="name"
                register={register}
                error={errors.name}
              />
              <Input
                title={t('Электронная почта')}
                name="email"
                type="email"
                error={errors.email}
                register={register}
              />
              <Input
                title={t('Телефон')}
                name="phone"
                error={errors.phone}
                register={register}
              />
              <Input
                title={t('Должность')}
                name="position"
                error={errors.position}
                register={register}
              />
              <div className="mt-8">
                <ButtonPrimary onClick={handleSubmit(editProfile)}>
                  {t('Сохранить')}
                </ButtonPrimary>
              </div>
            </Box>
            <Box color="white" padding="p-16-24">
              <div className="mb-16">
                <H3>{t('Сервис')}</H3>
              </div>
              <Select
                title={t('Язык')}
                name="language"
                register={langRegister}
                setValue={setLangValue}
                value={profile.language}
                options={langList.map((x: any) => [x.id, x.autonym])}
              />
              <div className="mt-8">
                <ButtonPrimary onClick={handleLangSubmit(editLanguage)}>
                  {t('Сохранить')}
                </ButtonPrimary>
              </div>
            </Box>
          </form>
          
          {profile?.user_type !== 'specialist' && (
            <OrganisationSection company={profile?.company} />
          )}
          {profile?.user_type !== 'employee' &&
            profile?.user_type !== 'specialist' && <ConnectedAccounts />}
          <Password />
        </>
      )}
    </PageTemplate>
  )
}

const OrganisationSection: FC<OrganisationSectionProps> = ({ company }) => {
  const { t } = useTranslation()
  
  if (!company) {
    // return (
      // <Box color="white" padding="p-16-24">
        // Loading...
      // </Box>
    // )
    return null
  }

  return (
    <Box color="white" padding="p-16-24">
      <div className="profile-page-section-title">
        <H3>{t('Организация')}</H3>
      </div>
      <ListItem title={t('Название')} text={company.name} />
      <ListItem title={t('ИНН')} text={company.inn || t('Не указан')} />
      <ListItem
        title={t('Юридический адрес')}
        text={company.address || t('Не указан')}
      />
    </Box>
  )
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="profile-page-skeleton-list">
        <Skeleton height={472} />
        <Skeleton height={200} />
        <Skeleton height={200} />
        <Skeleton height={300} />
      </div>
    </SkeletonTheme>
  )
}
