import { State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, post } from '../../api/api'
import Cookies from 'js-cookie'
import { loadComparisonSize } from '../equipment-comparison/equipmentComparisonSlice'

const token = Cookies.get('auth_token')

const initialState: State = {
  data: {},
  isInComparison: false,
  fetching: false,
  error: false,
  PDFLoading: false,
  PDFError: false,
  addingToComparison: false,
  addingError: false,
}

const equipmentItemSlice = createSlice({
  name: 'equipmentItem',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSuccessful(state, action: PayloadAction<any>) {
      const {
        data: { is_in_comparison, ...data },
      } = action.payload
      state.data = data
      state.isInComparison = is_in_comparison
      state.fetching = false
    },
    loadFailed(state) {
      state.fetching = false
      state.error = true
    },
    PDFLoadStarted(state) {
      state.PDFLoading = true
    },
    PDFLoadSuccessful(state) {
      state.PDFLoading = false
    },
    PDFLoadFailed(state) {
      state.PDFLoading = false
      state.PDFError = true
    },
    addToComparisonStarted(state) {
      state.addingToComparison = true
    },
    addToComparisonSuccessful(state) {
      state.isInComparison = true
      state.addingToComparison = false
    },
    addToComparisonFailed(state) {
      state.addingToComparison = false
      state.addingError = true
    },
  },
})

export const equipmentItemLoaded = (id: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentItemSlice.actions.loadStarted())
  get(`/equipment/${id}`)
    .then(json => {
      dispatch(equipmentItemSlice.actions.loadSuccessful(json))
    })
    .catch(() => {
      dispatch(equipmentItemSlice.actions.loadFailed())
      alert('Во время загрузки данных произошла ошибка.')
    })
}

export const PDFLoaded = (url: string, filename: string): AppThunk => async (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentItemSlice.actions.PDFLoadStarted())

  const response = await fetch(url, {
    headers: {
      Authorization: 'Token ' + token,
    },
  })

  if (!response.ok) {
    dispatch(equipmentItemSlice.actions.PDFLoadFailed())
    alert('Во время загрузки отчета произошла ошибка...')
    return
  }

  const blob = await response.blob()
  const objectUrl = window.URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = objectUrl
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(objectUrl)

  dispatch(equipmentItemSlice.actions.PDFLoadSuccessful())
}

export const addToComparison = (id: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentItemSlice.actions.addToComparisonStarted())
  post(`/equipment/${id}/to-comparison/`)
    .then(() => {
      dispatch(equipmentItemSlice.actions.addToComparisonSuccessful())
      dispatch(loadComparisonSize())
    })
    .catch(() => {
      dispatch(equipmentItemSlice.actions.addToComparisonFailed())
      alert('Во время изменения данных произошла ошибка.')
    })
}

export default equipmentItemSlice.reducer
