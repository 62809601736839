import { Params, State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { del, get, post } from '../../api/api'

const initialState: State = {
  data: [],
  fetching: false,
  error: false,
  adding: false,
  addingSuccess: false,
  addingError: false,
  addingErrorMessage: '',
  deleting: false,
  deletingError: false,
}

const connectedAccountsSlice = createSlice({
  name: 'connectedAccounts',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<[]>) {
      state.fetching = false
      state.data = action.payload
    },
    loadFailed(state) {
      state.error = true
      state.fetching = false
    },
    addStarted(state) {
      state.addingSuccess = false
      state.addingError = false
      state.addingErrorMessage = ''
      state.adding = true
    },
    addSucceed(state, action) {
      state.adding = false
      state.addingSuccess = true
      state.data = [...state.data, action.payload]
    },
    addFailed(state, action) {
      state.adding = false
      state.addingError = true
      state.addingErrorMessage = action.payload
    },
    deleteStarted(state) {
      state.deleting = true
    },
    deleteSucceed(state, action) {
      state.deleting = false
      state.data = state.data.filter(
        (item: Account) => item.id !== action.payload,
      )
    },
    deleteFailed(state) {
      state.deleting = false
      state.deletingError = true
    },
    clearSuccess(state) {
      state.addingSuccess = false
    },
  },
})

export const accountsLoaded = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(connectedAccountsSlice.actions.loadStarted())
  get('/account')
    .then(({ data }) => {
      dispatch(connectedAccountsSlice.actions.loadSucceed(data))
    })
    .catch(() => {
      dispatch(connectedAccountsSlice.actions.loadFailed())
    })
}

export const accountAdded = (email: Params): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(connectedAccountsSlice.actions.addStarted())
  post('/account', email)
    .then(() => {
      dispatch(connectedAccountsSlice.actions.addSucceed(email))
    })
    .catch(res => {
      dispatch(connectedAccountsSlice.actions.addFailed(res.email))
    })
}

export const accountDeleted = (id: number): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(connectedAccountsSlice.actions.deleteStarted())
  del(`/account/${id}`).then(() => {
    dispatch(connectedAccountsSlice.actions.deleteSucceed(id))
  })
}

export const clearSuccess = connectedAccountsSlice.actions.clearSuccess()

export default connectedAccountsSlice.reducer
