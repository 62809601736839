import Cookies from 'js-cookie'

const token = Cookies.get('auth_token')
const baseUrl = `${process.env.REACT_APP_BASE_URL}`
const landingUrl = `${process.env.REACT_APP_LANDING_URL}`

export let controller = new AbortController()

export const fetchRequest = (
  path: string,
  method: string,
  params?: object,
): Promise<any> => {
  if (!params) {
    controller = new AbortController()
    return fetch(baseUrl + path, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Token ${token}`,
      },
      signal: controller.signal,
    })
  }

  return fetch(baseUrl + path, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
    body: JSON.stringify(params),
  })
}

export const post = (path: string, params?: object): Promise<any> => {
  return fetchRequest(path, 'POST', params).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }
    if (res.status === 400) {
      return res.json().then((err: string) => {
        throw err
      })
    }

    return res.json()
  })
}

export const del = (path: string, params?: object): Promise<any> => {
  return fetchRequest(path, 'DELETE', params).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }
  })
}

export const get = (path: string, params?: object): Promise<any> => {
  return fetchRequest(path, 'GET', params).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }

    return res.json()
  })
}

export const patch = (path: string, params: object): Promise<any> => {
  return fetchRequest(path, 'PATCH', params).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }

    return res.json()
  })
}

export const put = (path: string, params: object): Promise<any> => {
  return fetchRequest(path, 'PUT', params).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }
    if (res.status === 400) {
      return res.json().then((err: string) => {
        throw err
      })
    }

    return res.json()
  })
}

export const postFile = (path: string, params: FormData): Promise<any> => {
  return fetch(baseUrl + path, {
    method: 'POST',
    headers: {
      Authorization: `Token ${token}`,
    },
    body: params,
  }).then(res => {
    if (res.status === 403 || res.status === 401) {
      Cookies.remove('auth_token')
      document.location.href = landingUrl
    }

    return res.json()
  })
}

export const getFile = (path: string, name: string): void => {
  fetch(baseUrl + path, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      Authorization: `Token ${token}`,
    },
  })
    .then(res => {
      if (res.status === 403 || res.status === 401) {
        Cookies.remove('auth_token')
        document.location.href = landingUrl
      }

      return res.blob()
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)

      document.body.appendChild(link)

      link.click()

      link.parentNode!.removeChild(link)
    })
}
