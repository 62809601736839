import { FC, useEffect, useRef, useMemo, useState } from 'react'
import {
  addToComparison,
  equipmentItemLoaded,
  PDFLoaded,
} from './equipmentItemSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import { useHistory, useParams, Link, useLocation } from 'react-router-dom'
import { Box, ConnectedBox, LinkButton } from '../../ui'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { searchBarStringSet } from '../../features/search-bar/searchBarSlice'
import { PageTemplate } from '../../ui'
import '../../styles/spacing.scss'
import '../../styles/utilities.scss'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { loadComparisonSize } from '../equipment-comparison/equipmentComparisonSlice'

interface LocationState {
  cameFromSearch?: boolean
}

export const EquipmentItemPage: FC = () => {
  const equipmentItemState = useSelector(
    (state: RootState) => state.equipmentItem,
  )
  const profile = useSelector((state: RootState) => state.profile)
  const hasAccess = useMemo(
    () =>
      profile?.data?.company?.active_invoice ||
      profile?.data?.user_type === 'specialist',
    [profile?.data?.company?.active_invoice, profile?.data?.user_type],
  )
  const data: any = equipmentItemState.data
  const isInComparison: boolean = equipmentItemState.isInComparison
  const isFetching = equipmentItemState.fetching
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation<LocationState>()
  const scrollToRef = useRef<HTMLDivElement | any>()
  const [fetchingCounter, setFetchingCounter] = useState(0)

  useEffect(() => {
    let intervalId: any = null
    if (isFetching) {
      intervalId = setInterval(() => {
        setFetchingCounter(fetchingCounter + 1)
      }, 1000)
    }

    if (!isFetching) {
      setFetchingCounter(0)
    }

    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    dispatch(equipmentItemLoaded(id))
  }, [dispatch, id])

  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: scrollToRef.current && scrollToRef.current.offsetTop,
    })
  }, [])

  const { t } = useTranslation()
  return (
    <>
      {isFetching || !data ? (
        <PageTemplate title={t('Поиск оборудования')}>
          <SkeletonList fetchingCounter={fetchingCounter} />
        </PageTemplate>
      ) : (
        <>
          <div ref={scrollToRef}></div>
          <PageTemplate title={t('Поиск оборудования')}>
            <div className="top-panel">
              {location.state?.cameFromSearch && (
                <LinkButton
                  icon="icons/back-blue.svg"
                  type="primary"
                  onClick={() => history.goBack()}
                >
                  {t('Результаты поиска')}
                </LinkButton>
              )}
              <div className="button-group">
                <LinkButton
                  icon="icons/download-blue.svg"
                  type="primary"
                  onClick={() =>
                    dispatch(
                      PDFLoaded(
                        `${process.env.REACT_APP_BASE_URL}/equipment/${id}/unload/`,
                        `${data.name.slice(0, 100)}.pdf`,
                      ),
                    )
                  }
                >
                  {t('Сохранить в PDF')}
                </LinkButton>
                {isInComparison ? (
                  <LinkButton
                    icon="icons/comparison-add-yellow.svg"
                    type="primary"
                    onClick={() => history.push('/equipment/comparison')}
                  >
                    {t('Перейти в сравнение')}
                  </LinkButton>
                ) : (
                  <LinkButton
                    icon="icons/comparison-add-blue.svg"
                    type="primary"
                    onClick={() => {
                      dispatch(addToComparison(id))
                    }}
                  >
                    {t('Добавить в сравнение')}
                  </LinkButton>
                )}
              </div>
            </div>
            <div className="mt-16">
              <Box color="white" padding="p-16">
                <section className="content-wrapper">
                  <h4 className="eq-content-heading">
                    {data && data.name !== '' ? data.name : '—'}
                  </h4>
                  {hasAccess && (
                    <div className="info-row mt-16">
                      <span className="info-row__name">
                        {t('Условия поставки')}
                      </span>
                      <span className="info-row__value">
                        {data.terms !== '' ? data.terms : '—'}
                      </span>
                    </div>
                  )}
                  <div className="info-row">
                    <span className="info-row__name">
                      {t('Пункт поставки')}
                    </span>
                    <span className="info-row__value">
                      {data.destination !== '' ? data.destination : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Код ТН ВЭД')}</span>
                    <span className="info-row__value">
                      {data.code !== '' ? data.code : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">
                      {t('Наименование отправителя')}
                    </span>
                    <span className="info-row__value">
                      {data.seller !== '' ? (
                        <Link
                          to={`/counterparty-check#${encodeURIComponent(
                            data.seller,
                          )}`}
                          onClick={() => dispatch(searchBarStringSet(''))}
                        >
                          {data.seller}
                        </Link>
                      ) : (
                        '—'
                      )}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">
                      {t('Страна происхождения товара')}
                    </span>
                    <span className="info-row__value">
                      {data.manufacturer_country !== ''
                        ? data.manufacturer_country
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">
                      {t('Страна отправления')}
                    </span>
                    <span className="info-row__value">
                      {data.seller_country !== '' ? data.seller_country : '—'}
                    </span>
                  </div>
                  {hasAccess && data.manufacturer && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Изготовитель')}
                      </span>
                      <span className="info-row__value">
                        {data.manufacturer !== '' ? (
                          <Link
                            to={`/counterparty-check#${encodeURIComponent(
                              data.manufacturer,
                            )}`}
                            onClick={() => dispatch(searchBarStringSet(''))}
                          >
                            {data.manufacturer}
                          </Link>
                        ) : (
                          '—'
                        )}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.models && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Модели, артикулы...')}
                      </span>
                      <span className="info-row__value">{data.models}</span>
                    </div>
                  )}
                  {hasAccess && data.amount && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Кол-во ед. изм.')}
                      </span>
                      <span className="info-row__value">
                        {Number(data.amount).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.measure_unit && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Единица измерения')}
                      </span>
                      <span className="info-row__value">
                        {data.measure_unit}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.net_weight && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Вес нетто')}</span>
                      <span className="info-row__value">
                        {Number(data.net_weight).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.invoice_price && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Фактурная стоимость')}
                      </span>
                      <span className="info-row__value">
                        {Number(data.invoice_price).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.stat_price && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Статистическая стоимость')}
                      </span>
                      <span className="info-row__value">
                        {Number(data.stat_price).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.customs_value && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Таможенная стоимость')}
                      </span>
                      <span className="info-row__value">
                        {data.customs_value}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.customs_value_currency && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Валюта таможенной стоимости')}
                      </span>
                      <span className="info-row__value">
                        {data.customs_value_currency}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.g071 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Таможенный орган')}
                      </span>
                      <span className="info-row__value">{data.g071}</span>
                    </div>
                  )}
                  {hasAccess && data.gd1 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Дата завершения таможенного оформления')}
                      </span>
                      <span className="info-row__value">{data.gd1}</span>
                    </div>
                  )}
                  {hasAccess && data.g541d && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t(
                          'Дата выдачи Свидетельства о включении в Реестр таможенных брокеров (представителей)',
                        )}
                      </span>
                      <span className="info-row__value">{data.g541d}</span>
                    </div>
                  )}
                  {hasAccess && data.g230 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Дата курса валюты')}
                      </span>
                      <span className="info-row__value">{data.g230}</span>
                    </div>
                  )}
                  {hasAccess && data.g0121 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Таможенный режим')}
                      </span>
                      <span className="info-row__value">{data.g0121}</span>
                    </div>
                  )}
                  {hasAccess && data.g021_region && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код региона отправителя')}
                      </span>
                      <span className="info-row__value">
                        {data.g021_region}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.g023 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Итоговый адрес отправителя')}
                      </span>
                      <span className="info-row__value">{data.g023}</span>
                    </div>
                  )}
                  {hasAccess && data.g032 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Общее количество комплектов ТД')}
                      </span>
                      <span className="info-row__value">{data.g032}</span>
                    </div>
                  )}
                  {hasAccess && data.g05 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Всего наименований товаров')}
                      </span>
                      <span className="info-row__value">{data.g05}</span>
                    </div>
                  )}
                  {hasAccess && data.g06 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Количество мест')}
                      </span>
                      <span className="info-row__value">{data.g06}</span>
                    </div>
                  )}
                  {hasAccess && data.g07 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код вида таможенной декларации')}
                      </span>
                      <span className="info-row__value">{data.g07}</span>
                    </div>
                  )}
                  {hasAccess && data.g081_region && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код региона получателя')}
                      </span>
                      <span className="info-row__value">
                        {data.g081_region}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.g082 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Получатель')}</span>
                      <span className="info-row__value">{data.g082}</span>
                    </div>
                  )}
                  {hasAccess && data.g0831 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Страна получателя')}
                      </span>
                      <span className="info-row__value">{data.g0831}</span>
                    </div>
                  )}
                  {hasAccess && data.g083 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Адрес получателя')}
                      </span>
                      <span className="info-row__value">{data.g083}</span>
                    </div>
                  )}
                  {hasAccess && data.g092 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Наименование контрактодержателя')}
                      </span>
                      <span className="info-row__value">{data.g092}</span>
                    </div>
                  )}
                  {hasAccess && data.g0931 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код страны контрактодержателя')}
                      </span>
                      <span className="info-row__value">{data.g0931}</span>
                    </div>
                  )}
                  {hasAccess && data.g093 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Адрес контрактодержателя')}
                      </span>
                      <span className="info-row__value">{data.g093}</span>
                    </div>
                  )}
                  {hasAccess && data.g11 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код торгующей страны')}
                      </span>
                      <span className="info-row__value">{data.g11}</span>
                    </div>
                  )}
                  {hasAccess && data.g142 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Наименование декларанта')}
                      </span>
                      <span className="info-row__value">{data.g142}</span>
                    </div>
                  )}
                  {hasAccess && data.g1431 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Страна декларанта')}
                      </span>
                      <span className="info-row__value">{data.g1431}</span>
                    </div>
                  )}
                  {hasAccess && data.g143 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Адрес декларанта')}
                      </span>
                      <span className="info-row__value">{data.g143}</span>
                    </div>
                  )}
                  {hasAccess && data.g0231 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код страны отправителя')}
                      </span>
                      <span className="info-row__value">{data.g0231}</span>
                    </div>
                  )}
                  {hasAccess && data.g17a && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Страна назначения')}
                      </span>
                      <span className="info-row__value">{data.g17a}</span>
                    </div>
                  )}
                  {hasAccess && data.g180 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Кол-во транспортных средств при отправлении')}
                      </span>
                      <span className="info-row__value">{data.g180}</span>
                    </div>
                  )}
                  {hasAccess && data.g300 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Местонахождение товаров')}
                      </span>
                      <span className="info-row__value">{data.g300}</span>
                    </div>
                  )}
                  {hasAccess && data.g084b && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код СОАТО получателя')}
                      </span>
                      <span className="info-row__value">{data.g084b}</span>
                    </div>
                  )}
                  {hasAccess && data.g094b && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код СОАТО контрактодержателя')}
                      </span>
                      <span className="info-row__value">{data.g094b}</span>
                    </div>
                  )}
                  {hasAccess && data.g221 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код валюты цены договора')}
                      </span>
                      <span className="info-row__value">{data.g221}</span>
                    </div>
                  )}
                  {hasAccess && data.g222 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Общая сумма по счету')}
                      </span>
                      <span className="info-row__value">{data.g222}</span>
                    </div>
                  )}
                  {hasAccess && data.g23 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Курс валюты')}</span>
                      <span className="info-row__value">{data.g23}</span>
                    </div>
                  )}
                  {hasAccess && data.g024b && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код СОАТО отправителя')}
                      </span>
                      <span className="info-row__value">{data.g024b}</span>
                    </div>
                  )}
                  {hasAccess && data.g144b && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код СОАТО декларанта')}
                      </span>
                      <span className="info-row__value">{data.g144b}</span>
                    </div>
                  )}
                  {hasAccess && data.g29 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код таможни на границе')}
                      </span>
                      <span className="info-row__value">{data.g29}</span>
                    </div>
                  )}
                  {hasAccess && data.g31_12 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Товарный знак')}
                      </span>
                      <span className="info-row__value">{data.g31_12}</span>
                    </div>
                  )}
                  {hasAccess && data.g281 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код ОКПО банка/паспорт сделки')}
                      </span>
                      <span className="info-row__value">{data.g281}</span>
                    </div>
                  )}
                  {hasAccess && data.g31_20 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код наличия упаковки товара')}
                      </span>
                      <span className="info-row__value">{data.g31_20}</span>
                    </div>
                  )}
                  {hasAccess && data.g31_3 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Количество контейнеров')}
                      </span>
                      <span className="info-row__value">{data.g31_3}</span>
                    </div>
                  )}
                  {hasAccess && data.g31_82 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t(
                          'Код единицы измерения, отличной от основной и дополнительной',
                        )}
                      </span>
                      <span className="info-row__value">{data.g31_82}</span>
                    </div>
                  )}
                  {hasAccess && data.g36 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Преференции')}</span>
                      <span className="info-row__value">{data.g36}</span>
                    </div>
                  )}
                  {hasAccess && data.g37 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Процедура')}</span>
                      <span className="info-row__value">{data.g37}</span>
                    </div>
                  )}
                  {hasAccess && data.g35 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Вес брутто')}</span>
                      <span className="info-row__value">
                        {Number(data.g35).toFixed(2)}
                      </span>
                    </div>
                  )}
                  {hasAccess && data.g39 && (
                    <div className="info-row">
                      <span className="info-row__name">{t('Квота')}</span>
                      <span className="info-row__value">{data.g39}</span>
                    </div>
                  )}
                  {hasAccess && data.g41a && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Код дополнительной единицы измерения')}
                      </span>
                      <span className="info-row__value">{data.g41a}</span>
                    </div>
                  )}
                  {hasAccess && data.g43 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Признак корректировки таможенной стоимости')}
                      </span>
                      <span className="info-row__value">{data.g43}</span>
                    </div>
                  )}
                  {hasAccess && data.g430 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Метод определения таможенной стоимости')}
                      </span>
                      <span className="info-row__value">{data.g430}</span>
                    </div>
                  )}
                  {hasAccess && data.g45 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Таможенная стоимость')}
                      </span>
                      <span className="info-row__value">{data.g45}</span>
                    </div>
                  )}
                  {hasAccess && data.g452 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Предыдущее значение таможенной стоимости')}
                      </span>
                      <span className="info-row__value">{data.g452}</span>
                    </div>
                  )}
                  {hasAccess && data.rubedizm && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Цена РУБ за ед изм')}
                      </span>
                      <span className="info-row__value">{data.rubedizm}</span>
                    </div>
                  )}
                  {hasAccess && data.usdedizm && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Цена USD за ед изм')}
                      </span>
                      <span className="info-row__value">{data.usdedizm}</span>
                    </div>
                  )}
                  {hasAccess && data.usdkg && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t('Цена USD за кг')}
                      </span>
                      <span className="info-row__value">{data.usdkg}</span>
                    </div>
                  )}
                  {hasAccess && data.g541 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t(
                          'Номер Свидетельства о включении в Реестр таможенных брокеров (представителей)',
                        )}
                      </span>
                      <span className="info-row__value">{data.g541}</span>
                    </div>
                  )}
                  {hasAccess && data.k470 && (
                    <div className="info-row">
                      <span className="info-row__name">
                        {t(
                          'Общая сумма ТП, подлеж.взысканию/возврату по товару',
                        )}
                      </span>
                      <span className="info-row__value">{data.k470}</span>
                    </div>
                  )}
                </section>
              </Box>
              {!hasAccess && (
                <ConnectedBox color="blue">
                  <h3 className="wrapper-gradient__heading">
                    {t('Получите полную информацию об оборудовании')}
                  </h3>
                  <p className="wrapper-gradient__text">
                    {t(
                      'Узнайте стоимость, вес, изготовителя и прочие параметры ввезенного оборудования',
                    )}
                  </p>
                  <footer className="wrapper-gradient__footer">
                    <LinkButton type={'tertiary red-btn'}>
                      <Link to="/tariffs" style={{ color: '#fff' }}>
                        {t('Подключить тариф')}
                      </Link>
                    </LinkButton>
                  </footer>
                </ConnectedBox>
              )}
            </div>
          </PageTemplate>
        </>
      )}
    </>
  )
}

interface SkeletonProps {
  fetchingCounter?: number
}

const SkeletonList: FC<SkeletonProps> = ({ fetchingCounter }) => {
  const { t } = useTranslation()

  const isFetchingLong =
    fetchingCounter && fetchingCounter > 4 ? (
      <div className="loading-long">
        {t('Загружаем данные по оборудованию.')}
        <br />
        {t('Нужно подождать еще немного...')}
      </div>
    ) : (
      ''
    )

  return (
    <div className="mt-16 box-anim">
      <SkeletonTheme color="#E6EAF1">
        <div className="questions-lists-skeleton">
          {isFetchingLong}
          <Skeleton count={1} height={580} />
        </div>
      </SkeletonTheme>
    </div>
  )
}
