import { State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, del } from '../../api/api'

const initialState: State = {
  data: [],
  fetching: false,
  error: false,
  comparisonSize: 0,
  removing: false,
  removalError: false,
}

const equipmentComparisonSlice = createSlice({
  name: 'equipmentComparison',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSuccessful(state, action: PayloadAction<any[]>) {
      state.data = action.payload
      state.comparisonSize = action.payload.length
      state.fetching = false
    },
    loadFailed(state) {
      state.fetching = false
      state.error = true
    },
    sizeLoadStarted(state) {
      state.fetching = true
      state.error = false
    },
    sizeLoadSucceed(state, action: PayloadAction<number>) {
      state.fetching = false
      state.comparisonSize = action.payload
    },
    sizeLoadFailed(state) {
      state.fetching = false
      state.error = true
    },
    removeFromComparisonStarted(state) {
      state.removing = true
    },
    removeFromComparisonSuccessful(state, action: PayloadAction<string>) {
      const newData = state.data.filter(item => item.id !== action.payload)
      state.data = newData
      state.comparisonSize = newData.length
      state.removing = false
    },
    removeFromComparisonFailed(state) {
      state.removing = false
      state.removalError = true
    },
  },
})

export const loadEquipmentComparison = (): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentComparisonSlice.actions.loadStarted())
  get(`/equipment/comparison/`)
    .then(json => {
      dispatch(equipmentComparisonSlice.actions.loadSuccessful(json.records))
    })
    .catch(() => {
      dispatch(equipmentComparisonSlice.actions.loadFailed())
      alert('Во время загрузки данных произошла ошибка.')
    })
}

export const loadComparisonSize = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(equipmentComparisonSlice.actions.sizeLoadStarted())
  get(`/equipment/comparison-size`)
    .then(json => {
      dispatch(equipmentComparisonSlice.actions.sizeLoadSucceed(json.size))
    })
    .catch(() => {
      dispatch(equipmentComparisonSlice.actions.sizeLoadFailed())
      alert('Во время загрузки данных произошла ошибка.')
    })
}

export const removeFromComparison = (id: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentComparisonSlice.actions.removeFromComparisonStarted())
  del(`/equipment/${id}/remove-comparison/`)
    .then(() => {
      dispatch(
        equipmentComparisonSlice.actions.removeFromComparisonSuccessful(id),
      )
    })
    .catch(() => {
      dispatch(equipmentComparisonSlice.actions.removeFromComparisonFailed())
      alert('Во время изменения данных произошла ошибка.')
    })
}

export default equipmentComparisonSlice.reducer
