import { FC } from 'react'
import './StatusIndicator.scss'

interface StatusIndicatorProps {
  condition: string
}

export const StatusIndicator: FC<StatusIndicatorProps> = ({ condition }) => {
  return <div className={`status-indicator ${condition}`}></div>
}
