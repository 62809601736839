export const zhLocale = {
  'Связанные аккаунты': '关联账户',
  'Добавить пользователя': '添加用戶',
  'Вы можете привязать несколько аккаунтов, которые получат доступ к вашим заказам и смогут управлять ими': '您可以关联几个可以访问您的订单并能够管理它们的帐户',
  'Отправлено приглашение': '已成功发送邀请',
  'Это обязательное поле': '这是必填字段',
  'Введен некорректный email': '无效的电邮',
  'Электронная почта': '电邮',
  'Добавить': '加',
  'Загрузка...': '正在加载...',
  'Перетащите сюда файл для загрузки или': '拖放文件以上载或者',
  'выберите его вручную': '手动选择它',
  'Проверка контрагента': '企业检查',
  'Поиск оборудования': '搜索设备',
  'Опросные листы': '问卷调查',
  'Тарифы': '费率',
  'Выйти': '登出',
  'Заявки': '要求',
  'Убедитесь, что это значение содержит не менее 8 символов.': '确保此值至少为8个字符长。',
  'Пароли не совпадают': '密码不匹配',
  'Безопасность': '安全',
  'Старый пароль': '以前的密码',
  'Новый пароль': '新的密码',
  'Повторите новый пароль': '确认密码',
  'Сохранить': '保存',
  'Теплообменник': '热交换器',
  'Реактор': '反应器',
  'Гранулятор': '制粒机',
  'Фильтр': '过滤器',
  'Ничего не найдено': '什么也没找到',
  'Найти': '找到',
  'Заявка сформирована': '已生成请求',
  'В работе': '进行中',
  'Требуется доработка': '需要修改',
  'Выполнена': '做完了',
  'Отменена': '取消',
  'Введите название компании': '请输入公司的名称',
  'Возникла ошибка при загрузке данных.': '加载数据时出错。',
  'Загружаем отчет...': '正在加载报告...',
  'Сохранить в PDF': '保存为 PDF',
  'Общие сведения': '一般信息',
  'Уставной капитал': '注册资本',
  'юаней': '元',
  'Адрес': '地址',
  'Сфера деятельности': '活动领域',
  'Тип компании': '公司类型',
  'Юридическое лицо': '法人',
  'Физическое лицо': '自然人',
  'Регистрационный номер': '注册号码',
  'Статус': '阶段',
  'Работает': '工作',
  'Дата основания': '成立于',
  'Дата утверждения': '批准日期',
  'Код организации': '纳税企业识别号',
  'Получите полную информацию о контрагенте': '获取有关业务合作伙伴的完整信息',
  'Узнайте долговые обязательства, рейтинг контрагента и прочую детальную информацию, чтобы быть уверенным в сделке': '了解您的业务合作伙伴的债务义务，评级和其他细节，以确保交易',
  'Оформить подписку': '订阅',
  'Скачать полную версию': '下载完整版本',
  'У вас уже есть доступ к этому отчету': '您已经可以访问此报告',
  'в этом месяце': '这个月',
  'Сведения о персонале': '员工资料',
  'Контактная информация': '联系我们',
  'Телефон': '电话',
  'Сайт': '网站',
  'Информация об изменениях': '变更的信息',
  'До': '以前',
  'После': '以后',
  'Филиалы': '分公司',
  'Нет филиалов или нет информации о них': '没有分公司',
  'Сведения об акционерах': '股东信息',
  'Акционер': '股东',
  'Тип': '类型',
  'Физ. лицо': '法人',
  'Юр. лицо': '自然人',
  'Первоначальный взнос (10тыс.¥)': '首期付款 (1万元）',
  'Доля': '百分比',
  'Дата': '日期',
  'Загружаем данные по компании.': '我们在加载公司数据。',
  'Нужно подождать еще немного...': '请稍等',
  'Результаты поиска': '搜寻结果',
  'Условия поставки': '交货条款',
  'Пункт поставки': '商品供应点',
  'Код ТН ВЭД': '关税号',
  'Наименование отправителя': '发件人',
  'Страна происхождения товара': '原产地',
  'Страна отправления': '启运国',
  'Изготовитель': '制造者',
  'Модели, артикулы...': '型号, 品号',
  'Кол-во ед. изм.': '测量单位数',
  'Единица измерения': '测量单位',
  'Вес нетто': '净重',
  'Фактурная стоимость': '发货单价值',
  'Статистическая стоимость': '统计价格',
  'Таможенная стоимость': '海关价值',
  'Валюта таможенной стоимости': '海关价值货币',
  'Таможенный орган': '海关机构',
  'Дата завершения таможенного оформления': '完成清关日期',
  'Дата выдачи Свидетельства о включении в Реестр таможенных брокеров (представителей)': 'The certificate of inclusion in the Register of customs brokers (representatives)。 发给日期',
  'Дата курса валюты': '货币汇率日期',
  'Таможенный режим': '海关制度',
  'Код региона отправителя': '寄件人的邮政编码',
  'Итоговый адрес отправителя': '寄件人地址',
  'Общее количество комплектов ': '总套数',
  'Всего наименований товаров': '商品名称',
  'Количество мест': '件数',
  'Код вида таможенной декларации': '报关类型代码',
  'Код региона получателя': '收货人的邮政编码',
  'Получатель': '收货人 ',
  'Страна получателя': '收货人国家',
  'Адрес получателя': '收货人地址',
  'Наименование контрактодержателя': '合约持有人姓名',
  'Код страны контрактодержателя': '合约持有人邮政编码',
  'Адрес контрактодержателя': '合约持有人地址',
  'Код торгующей страны': '贸易国家代码',
  'Наименование декларанта': '申报人姓名',
  'Страна декларанта': '申报人的国家',
  'Адрес декларанта': '申报人的地址',
  'Код страны отправителя': '发件人邮政编码的国家',
  'Страна назначения': '目的国',
  'Кол-во транспортных средств при отправлении': '离港车辆数量',
  'Местонахождение товаров': '货物的位置',
  'Код СОАТО получателя': 'Recipient\'s soato code ',
  'Код СОАТО контрактодержателя': 'Contract holder\'s soato code ',
  'Код валюты цены договора': '合约价格货币代码',
  'Общая сумма по счету': '总金额 ',
  'Курс валюты': '货币汇率',
  'Код СОАТО отправителя': 'Sender\'s soato code ',
  'Код СОАТО декларанта': 'Declarant\'s soato code',
  'Код таможни на границе': '边境海关编码',
  'Товарный знак': '商标',
  'Код ОКПО банка/паспорт сделки': 'OKPO (RNNBO)',
  'Код наличия упаковки товара': '产品包装可用性代码',
  'Количество контейнеров': '货柜数量',
  'Код единицы измерения, отличной от основной и дополнительной': 'Unit code other than the main and supplementary units',
  'Преференции': 'Preferences',
  'Процедура': '程序',
  'Вес брутто': '毛重',
  'Квота': '配额限制',
  'Код дополнительной единицы измерения': '附加单位代码',
  'Признак корректировки таможенной стоимости': '海关价值调整的标志',
  'Метод определения таможенной стоимости': '确定海关价值的方法',
  'Предыдущее значение таможенной стоимости': '以前的海关价值',
  'Цена РУБ за ед изм': '每单位卢布价格',
  'Цена USD за ед изм': '每单位美元价格',
  'Цена USD за кг': '每公斤美元',
  'Номер Свидетельства о включении в Реестр таможенных брокеров (представителей)': 'the Certificate of inclusion in the Register of customs brokers (representatives)',
  'Общая сумма ТП, подлеж.взысканию/возврату по товару': '*',
  'Получите полную информацию об оборудовании': '获取有关设备的完整信息',
  'Find out the cost, weight, manufacturer and other parameters of the imported equipment': '了解进口设备的成本、重量、厂家等参数',
  'Подключить тариф': '',
  'Загружаем данные по оборудованию.': '我们正在上传设备数据。 ',
  'Введите название оборудования': '输入设备名称',
  'Похоже, вы заблудились': '未找到页面',
  'Cтраница, которую вы ищете, была перемещена, удалена, переименована или никогда не существовала': '您要查找的页面已被移动、删除、重命名或从未存在',
  'На главную': '到主页面',
  'Профиль': '用户配置文件',
  'Персональные данные': '身份资料',
  'Имя': '名称',
  'Должность': '职位',
  'Сервис': '服务',
  'Язык': '语言',
  'Организация': '公司',
  'Название': '名称',
  'Не указан': '未指定',
  'Юридический адрес': '法定地址',
  'Введите категорию оборудования': '进入设备类别',
  'По вашему запросу ничего не найдено.': '您的查询没有找到任何内容。',
  'Все заявки': '所有请求 ',
  'Отменить заявку': '取消请求',
  'Завершить заявку': '完成请求',
  'E-mail пользователя': '用户的电子邮件',
  'E-mail специалиста': '专家电子邮件',
  'Отправить': '发送',
  'Отправить сообщение': '发送消息',
  'Тема': '主题',
  'Сообщение': '消息',
  'Заявка создана': '已生成请求',
  'Мы взяли в обработку вашу заявку. Если потребуются корректировки, технический специалист свяжется с вами. Если у вас появились вопросы, вы так же можете направить их на электронную почту специалиста, либо связаться со специалистом, отправив ему сообщение в заявке.': '您的申请正在处理中。 如果需要调整，技术人员将与您联系。 如果您有任何问题，您可以将它们发送到专家的电子邮件，或通过在应用程序中发送消息与专家联系。',
  'Загружен документ': '已上载的文件',
  'Загружены документы': '已上载的文件',
  'Создать заявку': '创建请求',
  'У вас пока нет ни одной заявки.': '你还没有任何请求。',
  'Завершённые заявки': '已完成的请求',
  'Название заявки': '请求名称',
  'Приложите к заявке': '在请求中附上问卷',
  'опросный лист': '',
  'и другие документы, которые помогут нам подобрать оборудование': '以及其他有助于我们选择设备的文件',
  'Создать': '要创建',
  'У вас подключен': '你已经激活了',
  'Действует до': '有效期至',
  'в месяц': '每月',
}