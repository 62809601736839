import { FC } from 'react'
import './Heading.scss'

interface HeadingProps {
  children: React.ReactNode
  className?: string
}

export const H1: FC<HeadingProps> = ({ children }) => {
  return <h1 className="h1">{children}</h1>
}

export const H3: FC<HeadingProps> = ({ children }) => {
  return <h3 className="h3">{children}</h3>
}
