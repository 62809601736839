import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import Cookies from 'js-cookie'
import store from './redux/configureStore'
import './styles/index.scss'

const render = () => {
  const token = Cookies.get('auth_token')

  if (!token) {
    // window.location.href = `${process.env.REACT_APP_LANDING_URL}`
    Cookies.set('auth_token', 'ff4e08c5f45bc23b3bc85ed545afadd84ab6fdc3')

    return null
  } else {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    )
  }
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App', render)
}
