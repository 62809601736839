import { FC, Fragment, useEffect, useState, useRef } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import {
  contragentInfoSearched,
  contragentInfoMore,
  PDFLoaded,
  PDFStateReset,
} from './contragentInfoSlice'
import { contragentSearched } from '../contragent-check/contragentCheckSlice'
import { profileLoaded } from '../profile/profileSlice'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { SearchBar } from '../../features'
import { Img } from '../../ui'
import { LinkButton } from '../../ui'
import { Box } from '../../ui'
import { ConnectedBox } from '../../ui'
import { PageTemplate } from '../../ui/templates'
import { declensionOfNumber } from '../../lib'
import '../../styles/spacing.scss'
import '../../styles/utilities.scss'
import './styles.scss'
import { useTranslation } from 'react-i18next'

export const ContragentInfoPage: FC = () => {
  const contragentInfoState = useSelector(
    (state: RootState) => state.contragentInfo,
  )
  const contragentState = useSelector(
    (state: RootState) => state.contragentCheck,
  )
  const profile = useSelector((state: RootState) => state.profile)
  const downloadsRemain =
    profile?.data?.company?.active_invoice?.downloads_remain
  const activeInvoice = profile?.data?.company?.active_invoice
  const dispatch = useDispatch()
  const { id }: any = useParams()
  const { data }: any = contragentInfoState
  const isFetching = contragentInfoState.fetching
  const isFetchingMore = contragentInfoState.fetchingMore
  const isFullPDFLoading = contragentInfoState.FullPDFLoading
  const isFullPDFSucceed = contragentInfoState.FullPDFSucceed
  const isError = contragentInfoState.error
  const [fetchingCounter, setFetchingCounter] = useState(0)
  const isDownloaded = data && data.is_downloaded

  const scrollToRef = useRef<HTMLDivElement | any>()

  const searchBarHints = [
    'Shandong Eagle',
    'Beijing Fish',
    'Nep-logistics',
    'China Ocean Shipping',
  ]

  useEffect(() => {
    dispatch(profileLoaded())
  }, [dispatch, downloadsRemain, isDownloaded])

  useEffect(() => {
    dispatch(PDFStateReset())
  }, [dispatch, id])

  // не нужно указывать зависимости, иначе не работает
  useEffect(() => {
    let intervalId: any = null
    if (isFetchingMore || isFetching) {
      intervalId = setInterval(() => {
        setFetchingCounter(fetchingCounter + 1)
      }, 1000)
    }

    if (!isFetching && !isFetchingMore) {
      setFetchingCounter(0)
    }

    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    dispatch(contragentInfoSearched(id))
  }, [dispatch, id])

  const formatNumber = (number: number) =>
    new Intl.NumberFormat().format(number)

  const allShareholderSum =
    data?.shareholders?.reduce(
      (acc: number, item: any) => (acc += item.sum), 0
    )

  const { t } = useTranslation()
  
  const downloadDeclension = declensionOfNumber(downloadsRemain, [
    'скачивание',
    'скачивания',
    'скачиваний',
  ])

  return (
    <>
      <PageTemplate title={t('Проверка контрагента')}>
        <SearchBar
          icon="icons/search-blue.svg"
          placeholder={t('Введите название компании')}
          dropdownDispatchFn={contragentSearched}
          isSearching={contragentState?.searching}
          dropdownData={contragentState.data}
          hints={searchBarHints}
        />
        {isError ? (
          <div className="contragent-error">
            {t('Возникла ошибка при загрузке данных.')}
          </div>
        ) : !isError && data && !isFetching ? (
          <>
            <div className="mt-16 contragent">
              <div className="company-logo-group">
                {data.logo ? <img src={data.logo} alt="" /> : ''}
              </div>
              <Box color="white" padding="p-16">
                <section className="content-wrapper">
                  <header className="content-header">
                    <h3 className="content-header__heading">
                      {data.name !== '' ? data.name : '—'}
                    </h3>
                    <div className="ml-auto pr-8">
                      {contragentInfoState.PDFLoading ? (
                        <span className="loading-pdf-msg">
                          {t('Загружаем отчет...')}
                        </span>
                      ) : (
                        <LinkButton
                          type="primary"
                          icon="icons/download-blue.svg"
                          onClick={() =>
                            dispatch(
                              PDFLoaded(
                                `${process.env.REACT_APP_BASE_URL}/counterparty/${id}/pdf`,
                                `${data.name}.pdf`,
                              ),
                            )
                          }
                        >
                          {t('Сохранить в PDF')}
                        </LinkButton>
                      )}
                    </div>
                  </header>

                  <h4 className="subheading mt-16">{t('Общие сведения')}</h4>

                  <div className="info-row">
                    <span className="info-row__name">{t('Уставной капитал')}</span>
                    <span className="info-row__value">
                      {data.registered_capital !== '' &&
                        data.registered_capital !== null
                        ? `${formatNumber(data.registered_capital)} ${t('юаней')}`
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Адрес')}</span>
                    <span className="info-row__value">
                      {data.address !== '' && data.address !== null
                        ? data.address
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Сфера деятельности')}</span>
                    <span className="info-row__value">
                      {data.business_scope !== '' &&
                        data.business_scope !== null
                        ? data.business_scope
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Тип компании')}</span>
                    <span className="info-row__value">
                      {t(data.type === 'juridical_person'
                        ? 'Юридическое лицо'
                        : 'Физическое лицо')}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">
                      {t('Регистрационный номер')}
                    </span>
                    <span className="info-row__value">
                      {data.registration_number !== '' &&
                        data.registration_number !== null
                        ? data.registration_number
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Статус')}</span>
                    <span className="info-row__value">
                      {data.status === 'working' && data.status !== null
                        ? t('Работает')
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Дата основания')}</span>
                    <span className="info-row__value">
                      {data.establishment_date !== '' &&
                        data.establishment_date !== null
                        ? data.establishment_date
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Дата утверждения')}</span>
                    <span className="info-row__value">
                      {data.approval_date !== '' && data.approval_date !== null
                        ? data.approval_date
                        : '—'}
                    </span>
                  </div>
                  <div className="info-row">
                    <span className="info-row__name">{t('Код организации')}</span>
                    <span className="info-row__value">
                      {data.organization_code !== '' &&
                        data.organization_code !== null
                        ? data.organization_code
                        : '—'}
                    </span>
                  </div>
                </section>
              </Box>
              <ConnectedBox color="blue">
                <h3 className="wrapper-gradient__heading">
                  {t('Получите полную информацию о контрагенте')}
                </h3>
                <p className="wrapper-gradient__text">
                  {t('Узнайте долговые обязательства, рейтинг контрагента и прочую детальную информацию, чтобы быть уверенным в сделке')}
                </p>
                {activeInvoice === null &&
                  profile.data.user_type !== 'specialist' ? (
                  <footer className="wrapper-gradient__footer">
                    <LinkButton type={'tertiary red-btn'}>
                      <Link to="/tariffs" style={{ color: '#fff' }}>
                        {t('Оформить подписку')}
                      </Link>
                    </LinkButton>
                  </footer>
                ) : (
                  <footer className="wrapper-gradient__footer">
                    <LinkButton
                      icon="icons/download-blue.svg"
                      type={
                        profile.data.user_type === 'specialist' ||
                          ((isDownloaded || downloadsRemain > 0) &&
                            !isFullPDFLoading)
                          ? 'tertiary'
                          : 'tertiary link-button-disabled'
                      }
                      onClick={() => {
                        dispatch(profileLoaded())
                        if (!isDownloaded) {
                          dispatch(
                            contragentInfoMore(
                              id,
                              `${process.env.REACT_APP_BASE_URL}/counterparty/${id}/pdf-full`,
                              `Full ${data.name}.pdf`,
                            ),
                          )
                          setTimeout(
                            () =>
                              window.scrollTo({
                                behavior: 'smooth',
                                top:
                                  scrollToRef.current &&
                                  scrollToRef.current.offsetTop,
                              }),
                            100,
                          )
                        } else {
                          dispatch(
                            PDFLoaded(
                              `${process.env.REACT_APP_BASE_URL}/counterparty/${id}/pdf-full`,
                              `Full ${data.name}.pdf`,
                              'full-pdf',
                            ),
                          )
                        }
                      }}
                    >
                      {t(isFullPDFLoading
                        ? 'Загружаем отчет...'
                        : 'Скачать полную версию')}
                    </LinkButton>
                    <p className="wrapper-gradient__text-footer">
                      {isDownloaded || isFullPDFSucceed
                        ? t('У вас уже есть доступ к этому отчету')
                        : profile.data.user_type !== 'specialist' &&
                        `${t('Осталось')} ${downloadsRemain} ${downloadDeclension} ${t('в этом месяце')}`}
                    </p>
                  </footer>
                )}
              </ConnectedBox>
            </div>
            {isDownloaded ? (
              <>
                <div className="mt-16">
                  <Box color="white" padding="p-16">
                    <section className="content-wrapper">
                      <h4 className="subheading">{t('Сведения о персонале')}</h4>
                      {data?.staff?.length ? (
                        data.staff.map((person: any, idx: number) => (
                          <Fragment key={`${person.name}${idx}`}>
                            <div className="personal-info">
                              <div className="personal-info__icon">
                                <Img src="icons/user-blue.svg" alt="" />
                              </div>
                              <div className="personal-info-content">
                                <div className="personal-info__title">
                                  {person.name}
                                </div>
                                <div className="personal-info__subtitle">
                                  {person.position}
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))
                      ) : (
                        <div className="personal-info-content">
                          <div className="personal-info__title">—</div>
                        </div>
                      )}
                    </section>
                  </Box>
                </div>
                <div className="mt-16">
                  <Box color="white" padding="p-16">
                    <section className="content-wrapper">
                      <h4 className="subheading">{t('Контактная информация')}</h4>
                      <div className="info-row">
                        <span className="info-row__name">{t('Телефон')}</span>
                        <span className="info-row__value">
                          {data.phone !== '' && data.phone !== null
                            ? data.phone
                            : '—'}
                        </span>
                      </div>
                      <div className="info-row">
                        <span className="info-row__name">
                          {t('Электронная почта')}
                        </span>
                        <span className="info-row__value">
                          {data.email !== '' && data.email !== null
                            ? data.email
                            : '—'}
                        </span>
                      </div>
                      <div className="info-row">
                        <span className="info-row__name">{t('Сайт')}</span>
                        <span className="info-row__value">
                          {data.website !== '' && data.website !== null
                            ? data.website
                            : '—'}
                        </span>
                      </div>
                      <div className="info-row">
                        <span className="info-row__name">{t('Адрес')}</span>
                        <span className="info-row__value">
                          {data.address !== '' ? data.address : '—'}
                        </span>
                      </div>
                    </section>
                  </Box>
                </div>
                <div className="mt-16">
                  <Box color="white" padding="p-16">
                    <section className="content-wrapper">
                      <h4 className="subheading">{t('Информация об изменениях')}</h4>
                      {data && data.changes && data.changes.length ? (
                        data.changes.map((change: any, idx: number) => (
                          <Fragment key={`${change.date}${idx}`}>
                            <div className="info-changes">
                              <div className="info-changes-row">
                                <span className="info-row__name info-changes__date">
                                  {change.date}
                                </span>
                                <span className="info-row__name-small info-changes__description mt-4">
                                  {change.type}
                                </span>
                              </div>
                              <div className="info-changes-halves">
                                <div className="info-changes-row half">
                                  <span className="info-row__name">{t('До')}</span>
                                  <span className="info-row__value">
                                    {change.before}
                                  </span>
                                </div>
                                <div className="info-changes-row half">
                                  <span className="info-row__name">{t('После')}</span>
                                  <span className="info-row__value">
                                    {change.after}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Fragment>
                        ))
                      ) : (
                        <div className="personal-info-content">
                          <div className="personal-info__title">—</div>
                        </div>
                      )}
                    </section>
                  </Box>
                </div>

                <div className="mt-16">
                  <Box color="white" padding="p-16">
                    <section className="content-wrapper">
                      <h4 className="subheading">{t('Филиалы')}</h4>
                      <div className="info-fillial-row mt-8">
                        {!data.braches ? (
                          <span className="info-row__name">
                            {t('Нет филиалов или нет информации о них')}
                          </span>
                        ) : (
                          data.branches &&
                          data.branches.map((branch: any) => (
                            <Fragment key={branch.name}>
                              <div className="info-row">
                                <span className="info-row__name">
                                  {branch.name}
                                </span>
                                <span className="info-row__value">
                                  {branch.registration_number}
                                </span>
                              </div>
                            </Fragment>
                          ))
                        )}
                      </div>
                    </section>
                  </Box>
                </div>

                <div className="mt-16">
                  <Box color="white" padding="p-16">
                    <section className="content-wrapper">
                      <h4 className="subheading">{t('Сведения об акционерах')}</h4>
                      {data && data.shareholders && data.shareholders.length ? (
                        data.shareholders.map(
                          (shareholder: any, idx: number) => {
                            return (
                              <Fragment key={shareholder.name}>
                                <div className="shareholders">
                                  <div>
                                    <span className="info-row__name shareholder-num">
                                      №
                                    </span>
                                    <span className="info-row__value">
                                      {idx + 1}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="info-row__name shareholder-name">
                                      {t('Акционер')}
                                    </span>
                                    <span className="info-row__value">
                                      {shareholder.name}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="info-row__name">{t('Тип')}</span>
                                    <span className="info-row__value">
                                      {t(shareholder.type === 'natural_person'
                                        ? 'Физ. лицо'
                                        : 'Юр. лицо')}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="info-row__name">
                                      {t('Первоначальный взнос (10тыс.¥)')}
                                    </span>
                                    <span className="info-row__value">
                                      {shareholder.sum !== '' &&
                                        shareholder.sum !== null
                                        ? Math.floor(shareholder.sum)
                                        : '—'}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="info-row__name">{t('Доля')}</span>
                                    <span className="info-row__value">
                                      {`${(
                                        (shareholder.sum / allShareholderSum) *
                                        100
                                      ).toFixed(2)}%`}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="info-row__name">{t('Дата')}</span>
                                    <span className="info-row__value">
                                      {shareholder.date !== '' &&
                                        shareholder.date !== null
                                        ? shareholder.date
                                        : '—'}
                                    </span>
                                  </div>
                                </div>
                              </Fragment>
                            )
                          },
                        )
                      ) : (
                        <div className="personal-info-content">
                          <div className="personal-info__title">—</div>
                        </div>
                      )}
                    </section>
                  </Box>
                </div>
              </>
            ) : isFetchingMore ? (
              <SkeletonList fetchingCounter={fetchingCounter} />
            ) : (
              ''
            )}
          </>
        ) : (
          <SkeletonList fetchingCounter={fetchingCounter} />
        )}
        <div ref={scrollToRef}></div>
      </PageTemplate>
    </>
  )
}

interface SkeletonProps {
  fetchingCounter?: number
  isLoadMore?: boolean
}

const SkeletonList: FC<SkeletonProps> = ({ fetchingCounter }) => {
  const { t } = useTranslation()
  
  const isFetchingLong =
    fetchingCounter && fetchingCounter > 4 ? (
      <div className="loading-long">
        {t('Загружаем данные по компании.')}<br /> 
        {t('Нужно подождать еще немного...')}
      </div>
    ) : (
      ''
    )

  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="questions-lists-skeleton">
        {isFetchingLong}
        <Skeleton count={1} height={700} />
      </div>
    </SkeletonTheme>
  )
}
