import { FC } from 'react'
import { Img } from '../img/Img'
import './Hamburger.scss'

interface HamburgerProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const Hamburger: FC<HamburgerProps> = ({ isOpen, setIsOpen }) => {
  const opened = isOpen ? 'hamburger__icon hidden' : 'hamburger__icon'
  const closed = isOpen ? 'hamburger__icon' : 'hamburger__icon hidden'
  return (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className="hamburger"
      aria-label="Navigation"
    >
      <Img
        className={opened}
        src="icons/hamburger.svg"
        alt=""
        aria-hidden="true"
      />
      <Img className={closed} src="icons/close.svg" alt="" aria-hidden="true" />
    </button>
  )
}
