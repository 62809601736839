import { FC } from 'react'
import './MainBox.scss'

interface MainBoxProps {
  children: React.ReactNode
}

export const MainBox: FC<MainBoxProps> = ({ children }) => {
  return <div className="main-box">{children}</div>
}
