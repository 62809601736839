import { FC } from 'react'
import { StatusIndicator } from '../../atoms'
import './Status.scss'
import { useTranslation } from 'react-i18next'

interface StatusProps {
  status: string
}

export const textByStatus = (status: string): string => {
  switch (status) {
    case 'new':
      return 'Заявка сформирована'
    case 'in_progress':
      return 'В работе'
    case 'pending':
      return 'Требуется доработка'
    case 'completed':
      return 'Выполнена'
    case 'canceled':
      return 'Отменена'
    default:
      return ''
  }
}

export const Status: FC<StatusProps> = ({ status }) => {
  const { t } = useTranslation()
  return (
    <div className="status">
      <StatusIndicator condition={status} />
      <div className="status-text">{t(textByStatus(status))}</div>
    </div>
  )
}
