const decCache: number[] = []
const decCases = [2, 0, 1, 1, 1, 2]

export const declensionOfNumber = (number: any, titles: string[]): string => {
  if (!decCache[number]) {
    decCache[number] =
      number % 100 > 4 && number % 100 < 20
        ? 2
        : decCases[Math.min(number % 10, 5)]
  }
  return titles[decCache[number]]
}

/* Primer

const downloadDeclension = declensionOfNumber(
  downloadsRemain, [
  'скачивание',
  'скачивания',
  'скачиваний',
]) ===> 0 скачиваний, 1 скачивание, 2 скачивания......

*/
