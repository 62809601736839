import { FC } from 'react'
import './SidebarBox.scss'

interface SidebarBoxProps {
  children: React.ReactNode
}

export const SidebarBox: FC<SidebarBoxProps> = ({ children }) => {
  return <aside className="sidebar-box">{children}</aside>
}
