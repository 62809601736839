import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonPrimary, Img, Input, LinkButton, Modal, Box, H3 } from '../../ui'
import { RootState } from '../../redux/configureStore'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  accountAdded,
  accountDeleted,
  accountsLoaded,
  clearSuccess,
} from './connectedAccountsSlice'
import { Account, Params } from './types'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import './ConnectedAccounts.scss'
import { useTranslation } from 'react-i18next'

export const ConnectedAccounts: FC = () => {
  const dispatch = useDispatch()
  const accounts = useSelector(
    (state: RootState) => state.connectedAccounts.data,
  )

  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    dispatch(clearSuccess)
    setModalOpen(!modalOpen)
  }

  useEffect(() => {
    dispatch(accountsLoaded())
  }, [dispatch])

  const { t } = useTranslation()
  return (
    <>
      <Box color="white" padding="p-16-24">
        <div className="connected-accounts-header">
          <H3>{t('Связанные аккаунты')}</H3>
          <LinkButton
            icon="icons/user-blue.svg"
            type="primary"
            onClick={openModal}
          >
            {t('Добавить пользователя')}
          </LinkButton>
        </div>
        {accounts.length !== 0 ? (
          <div className="connected-accounts-items">
            {accounts.map((item: Account) => (
              <ConnectedAccountsItem
                name={item.name}
                position={item.position}
                email={item.email}
                avatar_link={item.avatar_link}
                id={item.id}
                key={item.id}
              />
            ))}
          </div>
        ) : (
          <p className="connected-accounts-grey-text">
            {t('Вы можете привязать несколько аккаунтов, которые получат доступ к вашим заказам и смогут управлять ими')}
          </p>
        )}
      </Box>
      <Modal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        title={t('Добавить пользователя')}
      >
        <ModalContent modalOpen={modalOpen} setModalOpen={setModalOpen} />
      </Modal>
    </>
  )
}

const ConnectedAccountsItem: FC<Account> = ({
  avatar_link,
  name,
  position,
  email,
  id,
}) => {
  const dispatch = useDispatch()
  const handleDelete = () => dispatch(accountDeleted(id))

  const baseUrl = `${process.env.REACT_APP_BASE_URL}`

  const { t } = useTranslation()
  return (
    <section className="connected-accounts-item">
      {avatar_link ? (
        <img
          className="connected-accounts-item-avatar"
          src={baseUrl + avatar_link}
          alt=""
        />
      ) : (
        <Img
          alt=""
          src="icons/avatar-empty-2.svg"
          className="connected-accounts-item-avatar"
        />
      )}
      <div className="connected-accounts-item-info">
        <div>{name ? name : email}</div>
        <div className="connected-accounts-grey-text">{position}</div>
        <div className="connected-accounts-grey-text">
          {name ? email : t('Отправлено приглашение')}
        </div>
      </div>
      <LinkButton
        onClick={handleDelete}
        icon="icons/delete-grey.svg"
        type="secondary"
      />
    </section>
  )
}

interface ModalProps {
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  modalOpen: boolean
}

export const ModalContent: FC<ModalProps> = ({ setModalOpen }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  
  const addingSuccess = useSelector(
    (state: RootState) => state.connectedAccounts.addingSuccess,
  )
  const addingError = useSelector(
    (state: RootState) => state.connectedAccounts.addingError,
  )
  const addingErrorMessage = useSelector(
    (state: RootState) => state.connectedAccounts.addingErrorMessage,
  )
  const adding = useSelector(
    (state: RootState) => state.connectedAccounts.adding,
  )

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('Это обязательное поле'))
      .email(t('Введен некорректный email')),
  })

  const { register, errors, handleSubmit } = useForm<any>({
    resolver: yupResolver(validationSchema),
  })

  const addNewAccount = (data: Params) => {
    dispatch(accountAdded(data))
  }

  if (addingSuccess && !adding) {
    setModalOpen(false)
  }

  return (
    <>
      <form action="#!" className="modal-form">
        <Input
          type="text"
          name="email"
          placeholder={t('Электронная почта')}
          register={register}
          error={errors.email || addingError}
          errorMessage={addingErrorMessage}
        />
        {/*<ErrorMessage message={errors.email?.message} />*/}
        <div className="mt-32 modal-form__btn">
          <ButtonPrimary
            onClick={handleSubmit(addNewAccount)}
            isDisabled={adding}
          >
            {t('Добавить')}
          </ButtonPrimary>
        </div>
      </form>
    </>
  )
}
