import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { SidebarMenuItem } from '../../ui'
import { searchBarStringSet } from '../../features/search-bar/searchBarSlice'
import { resetToInitial } from '../../pages/equipment-search/equipmentSearchSlice'
import { MenuLogoutItem, MenuProfileItem, MenuRequestsItem } from './molecules'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../redux/rootReducer'
import './DropdownMenu.scss'
import { useTranslation } from 'react-i18next'
import { loadComparisonSize } from '../../pages/equipment-comparison/equipmentComparisonSlice'

interface DropdownMenuProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  height?: string
}

export const DropdownMenu: FC<DropdownMenuProps> = ({ isOpen, setIsOpen }) => {
  const profile = useSelector((state: RootState) => state.profile.data)
  const comparisonSize = useSelector(
    (state: RootState) => state.equipmentComparison.comparisonSize,
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(loadComparisonSize())
  }, [dispatch])

  const opened = isOpen
    ? 'dropdown-menu dropdown-menu--opened'
    : 'dropdown-menu dropdown-menu--closed'

  const { t } = useTranslation()
  return (
    <nav className={opened}>
      <div className="dropdown-menu__top">
        <span onClick={() => setIsOpen(false)}>
          <SidebarMenuItem
            to="/counterparty-check"
            icon="icons/check-circle.svg"
            onClick={() => dispatch(searchBarStringSet(''))}
          >
            {t('Проверка контрагента')}
          </SidebarMenuItem>
        </span>
        <span onClick={() => setIsOpen(false)}>
          <SidebarMenuItem
            to="/equipment/search"
            icon="icons/search.svg"
            onClick={() => {
              dispatch(searchBarStringSet(''))
              dispatch(resetToInitial())
            }}
          >
            {t('Поиск оборудования')}
          </SidebarMenuItem>
        </span>
        <span onClick={() => setIsOpen(false)}>
          <MenuRequestsItem />
        </span>
        <span onClick={() => setIsOpen(false)}>
          <SidebarMenuItem
            to="/questionnaires"
            icon="icons/questionnaire.svg"
            onClick={() => dispatch(searchBarStringSet(''))}
          >
            {t('Опросные листы')}
          </SidebarMenuItem>
        </span>
      </div>
      <div className="dropdown-menu__bottom">
        <span onClick={() => setIsOpen(false)}>
          <MenuProfileItem />
        </span>
        {comparisonSize > 0 && (
          <span onClick={() => setIsOpen(false)}>
            <SidebarMenuItem
              to="/equipment/comparison"
              icon="icons/comparison-add.svg"
              iconWidth={18}
              iconHeight={16}
              onClick={() => dispatch(searchBarStringSet(''))}
            >
              {t('Сравнение')}
              <span className="sidebar-menu-item__badge">{comparisonSize}</span>
            </SidebarMenuItem>
          </span>
        )}
        {profile?.user_type !== 'employee' &&
          profile?.user_type !== 'specialist' && (
            <span onClick={() => setIsOpen(false)}>
              <SidebarMenuItem
                to="/tariffs"
                icon="icons/tariff.svg"
                onClick={() => dispatch(searchBarStringSet(''))}
              >
                {t('Тарифы')}
              </SidebarMenuItem>
            </span>
          )}
        <MenuLogoutItem />
      </div>
    </nav>
  )
}
