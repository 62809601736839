import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonPrimary, PageTemplate, Box, Img, Modal, Input } from '../../ui'
import {
  AvailableTariffsProps,
  TariffPropertiesProps,
  TariffProps,
  YourTariffProps,
} from './types'
import { RootState } from '../../redux/configureStore'
import {
  tariffsChanged,
  tariffsLoaded,
  hideCompanyRequestModal,
  getCompanies,
  addCompany,
  setCurrentCompanyValue,
} from './tariffsSlice'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './styles.scss'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { SearchBar } from '../../features'

const renderTariffList = (data: any) =>
  [...data]
    .reverse()
    .sort((a, b) => b.enabled - a.enabled)
    .map((item: any) => (
      <TariffProperties
        key={item.id}
        flag={item.enabled}
        text={
          item.text_additional
            ? `${item.text} ${item.text_additional}`
            : item.text
        }
      />
    ))

export const TariffsPage: FC = () => {
  const dispatch = useDispatch()
  const tariffs = useSelector((state: RootState) => state.tariffs.data)
  const fetching = useSelector((state: RootState) => state.tariffs.fetching)
  const companiesSuggestions = useSelector(
    (state: RootState) => state.tariffs.companies,
  )
  const searchingCompanies = useSelector(
    (state: RootState) => state.tariffs.searchingCompanies,
  )
  const currentCompanyValue = useSelector(
    (state: RootState) => state.tariffs.currentCompany,
  )

  const profile = useSelector((state: RootState) => state.profile.data)
  const profileFetching = useSelector(
    (state: RootState) => state.profile.fetching,
  )
  const companyModalOpen = useSelector(
    (state: RootState) => state.tariffs.addingCompany,
  )
  const addCompanyError = useSelector(
    (state: RootState) => state.tariffs.addCompanyError,
  )
  const pendingTariffId = useSelector(
    (state: RootState) => state.tariffs.pendingTariffId,
  )

  const tariffPlan = profile.company && profile.company.active_invoice

  useEffect(() => {
    dispatch(tariffsLoaded())
  }, [dispatch])

  const { t } = useTranslation()
  return (
    <PageTemplate title={t('Тарифы')}>
      {fetching || profileFetching ? (
        <SkeletonList />
      ) : (
        <>
          {tariffPlan && (
            <YourTariff
              tariff={tariffPlan.plan.name}
              activeTo={tariffPlan.active_to}
            />
          )}
          <div className="tariffs-page-content">
            <StandartTariff tariffData={tariffs[0]} tariffPlan={tariffPlan} />
            <OptimalTariff tariffData={tariffs[1]} tariffPlan={tariffPlan} />
          </div>
        </>
      )}
      <Modal
        modalOpen={companyModalOpen}
        setModalOpen={() => dispatch(hideCompanyRequestModal())}
        title={t('Стандартный тариф')}
      >
        <div className="tariffs-modal-subtitle">
          Для оформления подписки укажите название компании или ИНН
        </div>
        <div className="mt-32">
          <SearchBar
            placeholder={t('Название компании или ИНН')}
            isSearching={searchingCompanies}
            dropdownDispatchFn={getCompanies}
            dropdownData={companiesSuggestions}
            dropdownDataType="companies"
            resultDispatchFn={setCurrentCompanyValue}
            errorMessage={addCompanyError}
          />
        </div>
        <div className="mt-8">
          <ButtonPrimary
            isDisabled={!currentCompanyValue}
            onClick={() =>
              pendingTariffId &&
              dispatch(addCompany(currentCompanyValue, pendingTariffId))
            }
          >
            {t('Выставить счёт')}
          </ButtonPrimary>
        </div>
      </Modal>
    </PageTemplate>
  )
}

const YourTariff: FC<YourTariffProps> = ({ tariff, activeTo }) => {
  const { t } = useTranslation()
  return (
    <Box color="blue" padding="p-12-16">
      <p className="your-tariff">
        {t('У вас подключен')} {tariff}
      </p>
      <p className="tariff-deadline">
        {t('Действует до')} {dayjs(activeTo).format('DD.MM.YYYY')}
      </p>
    </Box>
  )
}

const StandartTariff: FC<TariffProps> = ({ tariffData }) => {
  if (!tariffData) {
    return null
  }

  const tariffList = renderTariffList(tariffData.features)

  return (
    <AvailableTariffs
      id={tariffData.id}
      name={tariffData.name}
      price={tariffData.price}
      description={tariffData.description}
    >
      {tariffList}
    </AvailableTariffs>
  )
}

const OptimalTariff: FC<TariffProps> = ({ tariffData }) => {
  if (!tariffData) {
    return null
  }

  const tariffList = renderTariffList(tariffData.features)

  return (
    <AvailableTariffs
      id={tariffData.id}
      name={tariffData.name}
      price={tariffData.price}
      description={tariffData.description}
    >
      {tariffList}
    </AvailableTariffs>
  )
}

const AvailableTariffs: FC<AvailableTariffsProps> = ({
  children,
  name,
  price,
  description,
  id,
}) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  return (
    <Box color="white" padding="p-12-16">
      <div className="available-tariffs">
        <h3 className="available-tariffs-name">{name}</h3>
        <div className="available-tariffs-price">
          <span>{price} ₽</span>
          <span className="tariffs-page-grey-text">/{t('в месяц')}</span>
        </div>
        <p className="tariffs-page-grey-text">{description}</p>
        {children}
        <div className="available-tariffs-button">
          <ButtonPrimary onClick={() => dispatch(tariffsChanged(id))}>
            {t('Оформить подписку')}
          </ButtonPrimary>
        </div>
      </div>
    </Box>
  )
}

const TariffProperties: FC<TariffPropertiesProps> = ({ flag, text }) => {
  return (
    <div className="tariff-properties">
      <Img
        src={flag ? 'icons/checkbox-active.svg' : 'icons/checkbox-disable.svg'}
        className="tariff-properties-icon"
        alt=""
      />
      <span className={flag ? '' : 'tariffs-page-grey-text'}>{text}</span>
    </div>
  )
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="tariffs-page-header-skeleton">
        <Skeleton height={62} />
      </div>
      <div className="tariffs-page-content-skeleton">
        <Skeleton height={349} />

        <Skeleton height={349} />
      </div>
    </SkeletonTheme>
  )
}
