import { FC } from 'react'
import './SearchHint.scss'

interface SearchHintProps {
  children: string | React.ReactNode
  onClick: any
}

export const SearchHint: FC<SearchHintProps> = ({ children, onClick }) => {
  return (
    <span className="search-hint" onClick={onClick}>
      {children}
    </span>
  )
}
