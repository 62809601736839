import { FC, useEffect, useState } from 'react'
import { LinkButton, RadioButton } from '../../ui'
import { PageTemplate } from '../../ui/templates'
import { useHistory } from 'react-router-dom'
import '../../styles/spacing.scss'
import '../../styles/utilities.scss'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { ComparisonTable } from '../../ui/molecules/comparison-table/ComparisonTable'
import { loadEquipmentComparison } from './equipmentComparisonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'

export const EquipmentComparisonPage: FC = () => {
  const { data: comparisonItems, fetching, error } = useSelector(
    (state: RootState) => state.equipmentComparison,
  )
  const [mode, setMode] = useState('all')
  const { t } = useTranslation()
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadEquipmentComparison())
  }, [dispatch])

  const handleAllChange = () => {
    setMode('all')
  }

  const handleDifferencesChange = () => {
    setMode('differences')
  }

  return (
    <section>
      <PageTemplate title={t('Сравнение оборудования')}>
        {comparisonItems.length ? (
          <>
            <div className="top-panel">
              <LinkButton
                icon="icons/back-blue.svg"
                type="primary"
                onClick={() => history.goBack()}
              >
                {t('Поиск оборудования')}
              </LinkButton>
              {comparisonItems.length > 1 && (
                <div className="view-mode-selector">
                  <RadioButton
                    label="Все"
                    value={mode === 'all'}
                    onChange={handleAllChange}
                  />
                  <RadioButton
                    label="Только отличия"
                    value={mode === 'differences'}
                    onChange={handleDifferencesChange}
                  />
                </div>
              )}
            </div>
            <ComparisonTable items={comparisonItems} viewMode={mode} />
          </>
        ) : (
          <div>
            {fetching
              ? t('Загружаем данные...')
              : t('В сравнении нет элементов')}
          </div>
        )}
      </PageTemplate>
    </section>
  )
}
