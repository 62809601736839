import { FC } from 'react'
import './ListItem.scss'

interface ListItemProps {
  title: string
  text: string
}

export const ListItem: FC<ListItemProps> = ({ title, text }) => {
  return (
    <section className="list-item">
      <p className="list-item-title">{title}</p>
      <p>{text}</p>
    </section>
  )
}
