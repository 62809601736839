import { FC } from 'react'
import { Img } from '../img/Img'
import './HeroImage.scss'

interface HeroImageProps {
  image: string
}

export const HeroImage: FC<HeroImageProps> = ({ image }) => {
  return (
    <figure className="hero-image">
      <Img src={image} alt="" />
    </figure>
  )
}
