import { FC, useEffect } from 'react'
import {
  Accordion,
  Box,
  ButtonPrimary,
  Input,
  Status,
  StatusIndicator,
  LinkButton,
  H3,
  Img,
} from '../../ui'
import { StatusDropdown } from '../../ui/molecules/status-dropdown/StatusDropdown'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import {
  fileSent,
  messageSent,
  requestDetailLoaded,
  statusChanged,
} from './requestDetailSlice'
import { Dropbox } from '../../features'
import { useHistory, useParams } from 'react-router-dom'
import {
  FileProps,
  IFiles,
  IMessage,
  IStatus,
  MessagesSectionProps,
  RequestDetailProps,
  IEvent,
  EventItemProps,
  RequestDetailHeaderProps,
  SendingSectionProps,
} from './types'
import { filesDataCleared } from '../../features/dropbox/dropboxSlice'
import dayjs from 'dayjs'
import { useForm } from 'react-hook-form'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { yupResolver } from '@hookform/resolvers/yup'
import updateLocale from 'dayjs/plugin/updateLocale'
import * as Yup from 'yup'
import { applicationFileRegEx } from '../../config/constants'
import './styles.scss'
import { textByStatus } from '../../ui/molecules/status/Status'
import { useTranslation } from 'react-i18next'

export const RequestsDetailPage: FC = () => {
  const dispatch = useDispatch()
  const requestData: any = useSelector(
    (state: RootState) => state.requestDetail.data,
  )
  const fetching: boolean = useSelector(
    (state: RootState) => state.requestDetail.fetching,
  )

  // const messageSending = useSelector((state: RootState) => state.requestDetail.messageSending)
  // const messageSuccess = useSelector((state: RootState) => state.requestDetail.messageSuccess)

  const { id }: any = useParams()

  useEffect(() => {
    dispatch(requestDetailLoaded(id))
    dispatch(filesDataCleared())
  }, [dispatch, id])

  return (
    <>
      <RequestDetailHeader status={requestData.status} />
      {fetching ? (
        <SkeletonList />
      ) : (
        <>
          <RequestDetail
            name={requestData.name}
            id={requestData.id}
            email={requestData.email}
            status={requestData.status}
          />
          {requestData.status !== 'canceled' &&
            requestData.status !== 'completed' && (
              <>
                <FileSendingSection status={requestData.status} />
                <MessageSendingSection status={requestData.status} />
              </>
            )}
          <EventsSection data={requestData} />
        </>
      )}
    </>
  )
}

const RequestDetailHeader: FC<RequestDetailHeaderProps> = ({ status }) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const canceling: boolean = useSelector(
    (state: RootState) => state.requestDetail.canceling,
  )
  const profile = useSelector((state: RootState) => state.profile.data)

  const cancelRequest = (currentStatus: string) => {
    const cancelStatus = currentStatus === 'new' ? 'canceled' : 'completed'
    const event: IStatus = {
      status: cancelStatus,
      datetime: new Date(),
      type: 'status',
    }
    dispatch(statusChanged(id, { status: cancelStatus }, event))
  }

  const { t } = useTranslation()
  return (
    <div className="request-detail-page-header">
      <div>
        <LinkButton
          icon="icons/back-blue.svg"
          type="primary"
          onClick={() => history.goBack()}
          isDisabled={canceling}
        >
          {t('Все заявки')}
        </LinkButton>
      </div>
      {profile.user_type !== 'specialist' &&
        status !== 'canceled' &&
        status !== 'completed' && (
          <div className="request-detail-page-cancel-button">
            <LinkButton
              icon="icons/close-grey.svg"
              type="secondary"
              onClick={() => cancelRequest(status)}
            >
              {t(status === 'new' ? 'Отменить заявку' : 'Завершить заявку')}
            </LinkButton>
          </div>
        )}
    </div>
  )
}

const RequestDetail: FC<RequestDetailProps> = ({ id, name, email, status }) => {
  const dispatch = useDispatch()
  const profile = useSelector((state: RootState) => state.profile.data)

  const changeStatus = (status: IStatus) => {
    const event: IStatus = {
      status: status.status,
      datetime: new Date(),
      type: 'status',
    }
    dispatch(statusChanged(id, { status: status.status }, event))
  }

  const { t } = useTranslation()
  return (
    <Box color="white" padding="p-16">
      <div className="request-detail-info">
        <div className="request-detail-info-status">
          <div className="request-detail-info-id">#{id}</div>
          <Status status={status} />
          {profile.user_type === 'specialist' && status !== 'canceled' && (
            <StatusDropdown changeStatus={changeStatus} />
          )}
        </div>
      </div>
      <div>
        <h3 className="request-detail-h3">{name}</h3>
      </div>
      {email && (
        <>
          <p className="request-detail-page-grey-subtitle">
            {t(profile.user_type === 'specialist'
              ? 'E-mail пользователя'
              : 'E-mail специалиста')}
          </p>
          <p>{email}</p>
        </>
      )}
    </Box>
  )
}

const FileSendingSection: FC<SendingSectionProps> = ({ status }) => {
  const dispatch = useDispatch()
  const { id }: any = useParams()

  const files = useSelector((state: RootState) => state.dropbox.files)
  // const fileSending = useSelector((state: RootState) => state.dropbox.sending)
  const filesID: number[] = files.map(item => item.id)

  const sendFile = () => {
    const event = {
      datetime: new Date(),
      type: 'files',
      status: status,
      files: files.map(item => {
        return { id: item.id, link: '/uploads/application_files/' + item.name }
      }),
    }
    dispatch(fileSent(id, { files: filesID }, event))
    dispatch(filesDataCleared())
  }

  const { t } = useTranslation()
  return (
    <Dropbox>
      <div className="request-detail-page-dropbox-button">
        <ButtonPrimary onClick={sendFile}>{t('Отправить')}</ButtonPrimary>
      </div>
    </Dropbox>
  )
}

const MessageSendingSection: FC<SendingSectionProps> = ({ status }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { id }: any = useParams()

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required(t('Это обязательное поле')),
    text: Yup.string().required(t('Это обязательное поле')),
  })

  const { register, handleSubmit, setValue, errors } = useForm<IMessage>({
    resolver: yupResolver(validationSchema),
  })

  const sendMessage = (data: IMessage) => {
    const event = {
      ...data,
      datetime: new Date(),
      type: 'message',
      by_owner: true,
    }

    dispatch(messageSent(id, data, event))
    setValue('topic', '')
    setValue('text', '')
  }

  return (
    <div className="mb-16">
      <Accordion
        padding="p-16"
        title={
          <h2 className="request-detail-page-title">{t('Отправить сообщение')}</h2>
        }
        arrowColor="blue"
      >
        <form>
          <Input
            name="topic"
            title={t('Тема')}
            register={register}
            error={errors.topic}
          />
          <Input
            name="text"
            title={t('Сообщение')}
            register={register}
            error={errors.text}
          />
          <div className="mt-16">
            <ButtonPrimary onClick={handleSubmit(sendMessage)}>
              {t('Отправить')}
            </ButtonPrimary>
          </div>
        </form>
      </Accordion>
    </div>
  )
}

const EventsSection: FC<MessagesSectionProps> = ({ data }) => {
  const { events, files } = data

  const { t } = useTranslation()
  
  return (
    <Box color="white" padding="p-16">
      {events &&
        events.map((item: IEvent, idx) => (
          <EventsItem item={item} key={item.datetime + idx} />
        ))}

      <Event
        datetime={data.created_at}
        status="new"
        topic={t('Заявка создана')}
        text={t('Мы взяли в обработку вашу заявку. Если потребуются корректировки, технический специалист свяжется с вами. Если у вас появились вопросы, вы так же можете направить их на электронную почту специалиста, либо связаться со специалистом, отправив ему сообщение в заявке.')}
      >
        {files &&
          files.map((file: IFiles) => {
            const name =
              file.link &&
              decodeURIComponent(file.link).match(applicationFileRegEx)![1]
            return <File title={name} href={file.link} key={file.id} />
          })}
      </Event>
    </Box>
  )
}

const Event: FC<IEvent> = ({ datetime, status, topic, text, children }) => {
  dayjs.extend(updateLocale)

  dayjs.updateLocale('en', {
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
  })
  return (
    <>
      <div className="request-history-item-date">
        <StatusIndicator condition={status} />
        <div className="request-detail-page-grey-subtitle ml-20">
          {dayjs(datetime).format('DD MMMM YYYY, HH:mm')}
        </div>
      </div>
      <div className="request-history-item-info">
        <div className="pt-5 pb-8">
          <H3>{topic}</H3>
        </div>
        <p className="request-history-item-text">{text}</p>
        <div>{children}</div>
      </div>
    </>
  )
}

const EventsItem: FC<EventItemProps> = ({ item }) => {
  const { t } = useTranslation()
  if (item.type === 'files') {
    const topic =
      item.files!.length > 1 ? 'Загружены документы' : 'Загружен документ'
    return (
      <Event topic={t(topic)} datetime={item.datetime} status={item.status}>
        {item.files?.map((file, idx) => {
          const name =
            file.link &&
            decodeURIComponent(file.link).match(applicationFileRegEx)![1]
          return (
            <File
              href={file.link}
              title={name}
              key={file.link + file.id + idx}
            />
          )
        })}
      </Event>
    )
  }
  if (item.type === 'message') {
    return (
      <Event
        datetime={item.datetime}
        status={item.status}
        text={item.text}
        topic={item.topic}
        key={item.datetime}
      />
    )
  }
  if (item.type === 'status') {
    return (
      <Event
        datetime={item.datetime}
        status={item.status}
        text={item.text}
        topic={textByStatus(item.status)}
        key={item.datetime}
      />
    )
  }
  return null
}

const File: FC<FileProps> = ({ title, href }) => {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`

  return (
    <a
      className="file-link pb-12"
      href={baseUrl + href}
      target="_blank"
      rel="noreferrer"
    >
      <Img className="file-link-icon" src="icons/blue-file.svg" alt="" />
      <div className="file-link-title-blue">{title}</div>
    </a>
  )
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="request-detail-page-skeleton-list">
        <Skeleton height={74} />
        <div className="request-detail-page-dropbox-skeleton">
          <Skeleton height={80} />
        </div>
        <Skeleton height={57} />
        <Skeleton height={194} />
      </div>
    </SkeletonTheme>
  )
}
