import { State } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, getFile, post } from '../../api/api'

const initialState: State = {
  data: [],
  fetching: false,
  error: false,
  changeData: {},
  changing: false,
  changingError: false,
  addingCompany: false,
  pendingTariffId: null,
  companies: [],
  searchingCompanies: false,
  currentCompany: null,
  addCompanyError: '',
}

const tariffsSlice = createSlice({
  name: 'tariffs',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<[]>) {
      state.data = action.payload
      state.fetching = false
    },
    loadFailed(state) {
      state.error = true
    },
    changeStarted(state) {
      state.changing = true
    },
    changeSucceed(state) {
      // state.changeData = action.payload
      state.changing = false
    },
    changeFailed(state) {
      state.changing = false
      state.error = true
    },
    requestCompany(state) {
      state.addingCompany = true
    },
    stopCompanyRequest(state) {
      state.addingCompany = false
    },
    companySearchStarted(state) {
      state.companies = []
      state.searchingCompanies = true
    },
    companySearchSucceeded(state, action: PayloadAction<any[]>) {
      state.companies = action.payload
      state.searchingCompanies = false
    },
    companySearchFailed(state) {
      state.companies = []
      state.searchingCompanies = false
    },
    setCurrentCompanyValue(state, action: PayloadAction<any>) {
      state.currentCompany = action.payload
    },
    setAddCompanyError(state, action: PayloadAction<string>) {
      state.addCompanyError = action.payload
    },
    setPendingTariffId(state, action: PayloadAction<number | null>) {
      state.pendingTariffId = action.payload
    },
  },
})

export const tariffsLoaded = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(tariffsSlice.actions.loadStarted())
  get('/pricing')
    .then(json => {
      dispatch(tariffsSlice.actions.loadSucceed(json.data))
    })
    .catch(() => {
      dispatch(tariffsSlice.actions.loadFailed())
    })
}

export const tariffsChanged = (id: number): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(tariffsSlice.actions.changeStarted())
  get('/check-auth')
    .then(json => {
      if (json.has_company) {
        getFile(`/pricing/${id}`, 'Счет на оплату.pdf')
      } else {
        dispatch(tariffsSlice.actions.setPendingTariffId(id))
        dispatch(tariffsSlice.actions.requestCompany())
      }
    })
    .catch(() => {
      dispatch(tariffsSlice.actions.changeFailed())
    })
}

export const getCompanies = (query: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(tariffsSlice.actions.companySearchStarted())
  get(`/company/${query}`)
    .then(json => {
      dispatch(tariffsSlice.actions.companySearchSucceeded(json.data))
    })
    .catch(() => {
      dispatch(tariffsSlice.actions.companySearchFailed())
    })
}

export const addCompany = (
  value: {
    company_name: string
    company_inn: string
  },
  id: number,
): AppThunk => (dispatch: AppDispatch) => {
  post('/add-company', value)
    .then(() => {
      getFile(`/pricing/${id}`, 'Счет на оплату.pdf')
      dispatch(tariffsSlice.actions.setPendingTariffId(null))
      dispatch(tariffsSlice.actions.stopCompanyRequest())
      dispatch(tariffsSlice.actions.setAddCompanyError(''))
    })
    .catch(err => {
      err.company_name &&
        dispatch(tariffsSlice.actions.setAddCompanyError(err.company_name[0]))
      console.log(err)
    })
}

export const hideCompanyRequestModal = (): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(tariffsSlice.actions.stopCompanyRequest())
}

export const setCurrentCompanyValue = (value: any): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(
    tariffsSlice.actions.setCurrentCompanyValue(
      value
        ? {
            company_name: value.name,
            company_inn: value.inn,
          }
        : value,
    ),
  )
  dispatch(tariffsSlice.actions.setAddCompanyError(''))
}

export default tariffsSlice.reducer
