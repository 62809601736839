import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { State, Payload } from './types'
import { postFile } from '../../api/api'

const initialState: State = {
  files: [],
  sending: false,
  sendingError: false,
}

export const dropboxSlice = createSlice({
  name: 'dropbox',
  initialState,
  reducers: {
    sendStarted(state) {
      state.sending = true
    },
    sendSucceed(state, action: PayloadAction<Payload>) {
      const { payload } = action

      state.sending = false
      state.files = [
        ...state.files,
        { name: payload.params.name.replace(/ /g, '_'), id: payload.json.id },
      ]
    },
    sendFailed(state) {
      state.sending = false
      state.sendingError = true
    },
    removeSucceed(state, action: PayloadAction<number>) {
      state.files = state.files.filter(item => item.id !== action.payload)
    },
    dataCleared(state) {
      state.files = []
    },
  },
})

export const filesSent = (params: File): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(dropboxSlice.actions.sendStarted())

  const formData = new FormData()
  formData.append('file', params)

  postFile('/file', formData)
    .then(json => dispatch(dropboxSlice.actions.sendSucceed({ json, params })))

    .catch(() => {
      dispatch(dropboxSlice.actions.sendFailed())
      alert('Произошла ошибка при загрузке данных')
    })
}

interface FileRemove {
  payload: number
  type: string
}

interface ClearedData {
  payload: undefined
  type: string
}

export const fileRemoved = (id: number): FileRemove =>
  dropboxSlice.actions.removeSucceed(id)

export const filesDataCleared = (): ClearedData =>
  dropboxSlice.actions.dataCleared()

export default dropboxSlice.reducer
