import { FC } from 'react'
import './File.scss'
import { Img } from '../../../../ui'

interface FileProps {
  name: string
  onRemove: () => void
}

export const File: FC<FileProps> = ({ name, onRemove }) => {
  return (
    <div className="dropbox-file">
      <Img src="icons/file-black.svg" alt="" />
      <span className="dropbox-file-name">{name}</span>
      <button className="dropbox-file-remove-button" onClick={onRemove}>
        <Img src="icons/close-blue.svg" alt="" />
      </button>
    </div>
  )
}
