import { FC } from 'react'
import './EmptyResultMessage.scss'

interface EmptyResultMessageProps {
  children: React.ReactNode
}

export const EmptyResultMessage: FC<EmptyResultMessageProps> = ({
  children,
}) => {
  return <div className="empty-result-message">{children}</div>
}
