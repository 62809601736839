import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get, post } from '../../api/api'
import { IFile, IMessage, IStatus, Payload, State } from './types'

const initialState: State = {
  data: {
    events: [],
  },
  fetching: false,
  error: false,
  canceling: false,
  cancelingError: false,
  messageSending: false,
  messageError: false,
  messageSuccess: false,
  fileSending: false,
  fileError: false,
}

const requestDetailSlice = createSlice({
  name: 'requestDetail',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<Payload>) {
      state.data = action.payload
      state.fetching = false
    },
    loadFailed(state) {
      state.fetching = false
      state.error = true
    },

    messageSendStarted(state) {
      state.messageSending = true
      state.messageSuccess = false
      state.messageError = false
    },
    messageSendSucceed(state, action: PayloadAction<IMessage>) {
      state.messageSending = false
      state.data.events = [action.payload, ...state.data.events]
      state.messageSuccess = true
      state.data.status = action.payload.status
    },
    messageSendFailed(state) {
      state.messageError = true
      state.messageSending = false
    },

    fileSendStarted(state) {
      state.fileSending = true
      state.fileError = false
    },
    fileSendSucceed(state, action: PayloadAction<IMessage>) {
      state.data.events = [action.payload, ...state.data.events]
      state.fileSending = false
      state.data.status = action.payload.status
    },
    fileSendFailed(state) {
      state.fileSending = false
      state.fileError = false
    },

    cancelStarted(state) {
      state.canceling = true
      state.cancelingError = false
    },
    cancelSucceed(state, action: PayloadAction<IStatus>) {
      state.canceling = false
      state.data = { ...state.data, status: action.payload.status }
      state.data.events = [action.payload, ...state.data.events]
    },
    cancelFailed(state) {
      state.canceling = false
      state.cancelingError = true
    },
  },
})

export const requestDetailLoaded = (id: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(requestDetailSlice.actions.loadStarted())
  get(`/application/${id}`)
    .then(json => {
      dispatch(requestDetailSlice.actions.loadSucceed(json))
    })
    .catch(() => {
      dispatch(requestDetailSlice.actions.loadFailed())
    })
}

export const messageSent = (
  id: string,
  params: IMessage,
  event: IMessage,
): AppThunk => (dispatch: AppDispatch) => {
  dispatch(requestDetailSlice.actions.messageSendStarted())
  post(`/application/${id}/message`, params)
    .then(res => {
      dispatch(
        requestDetailSlice.actions.messageSendSucceed({
          ...event,
          status: res.new_app_status,
        }),
      )
    })
    .catch(error => {
      dispatch(requestDetailSlice.actions.messageSendFailed())
      alert(error)
    })
}

export const fileSent = (
  id: string,
  params: object,
  event: IFile,
): AppThunk => (dispatch: AppDispatch) => {
  dispatch(requestDetailSlice.actions.fileSendStarted())
  post(`/application/${id}/files`, params)
    .then(res => {
      dispatch(
        requestDetailSlice.actions.fileSendSucceed({
          ...event,
          status: res.new_app_status,
        }),
      )
    })
    .catch(error => {
      dispatch(requestDetailSlice.actions.fileSendFailed())
      alert(error)
    })
}

export const statusChanged = (
  id: string,
  params: IStatus,
  event: IStatus,
): AppThunk => (dispatch: AppDispatch) => {
  dispatch(requestDetailSlice.actions.cancelStarted())
  post(`/application/${id}/status`, params)
    .then(() => {
      dispatch(requestDetailSlice.actions.cancelSucceed(event))
    })
    .catch(error => {
      dispatch(requestDetailSlice.actions.cancelFailed())
      alert(error.status[0])
    })
}

export default requestDetailSlice.reducer
