import { FC } from 'react'
import { SidebarMenuButtonItem } from '../../../../ui'
import { useDispatch } from 'react-redux'
import { logout } from './menuLogoutItemSlice'
import { useTranslation } from 'react-i18next'

export const MenuLogoutItem: FC = () => {
  const dispatch = useDispatch()

  const handleClick = () => dispatch(logout())

  const { t } = useTranslation()
  return (
    <SidebarMenuButtonItem onClick={handleClick} icon="icons/logout.svg">
      {t('Выйти')}
    </SidebarMenuButtonItem>
  )
}
