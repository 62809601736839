import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State, IData } from './types'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { get } from '../../api/api'

const initialState: State = {
  data: [],
  searching: false,
  error: false,
  currentPage: 1,
  numberOfPages: 0,
  wasSearched: false,
  searchedTerm: ''
}

const equipmentSearchSlice = createSlice({
  name: 'equipmentSearch',
  initialState,
  reducers: {
    searchStarted(state) {
      state.data = []
      state.searching = true
      state.error = false
      state.currentPage = 1
      state.numberOfPages = 0
      state.searchedTerm = ''
    },
    searchSucceed(state, action: PayloadAction<IData>) {
      state.data = action.payload.data
      state.numberOfPages = action.payload.number_of_pages
      state.currentPage = 1
      state.searching = false
      state.error = false
    },
    searchFailed(state) {
      state.data = []
      state.searching = false
      state.error = true
    },
    getEquipments(state, action:PayloadAction<IData>) {
      // state.data.data = [...state.data.data, ...action.payload.data]
      state.data = action.payload.data
      state.numberOfPages = action.payload.number_of_pages
      state.searching = false
      state.error = false
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload
    },
    setWasSearched(state, action: PayloadAction<boolean>) {
      state.wasSearched = action.payload
    },
    setSearchedTerm(state, action: PayloadAction<string>) {
      state.searchedTerm = action.payload
      state.data = []
      state.searching = true
      state.error = false
    },
    resetToInitial(state) {
      state.data = []
      state.searching = false
      state.error = false
      state.currentPage = 1
      state.numberOfPages = 0
      state.wasSearched = false
      state.searchedTerm = ''
    }
  },
})

export const {resetToInitial} = equipmentSearchSlice.actions

export const equipmentSearched = (search: string): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentSearchSlice.actions.searchStarted())
  dispatch(equipmentSearchSlice.actions.setWasSearched(false))
  get(`/equipment/search/${search}`)
    .then(json => {
      dispatch(equipmentSearchSlice.actions.searchSucceed(json))
      dispatch(equipmentSearchSlice.actions.setWasSearched(true))
    })
    .catch(() => {
      dispatch(equipmentSearchSlice.actions.searchFailed())
      alert('Во время поиска произошла ошибка.')
    })
}

export const loadEquipments = (search: string, page: number): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(equipmentSearchSlice.actions.setWasSearched(false))
  dispatch(equipmentSearchSlice.actions.setSearchedTerm(search))
  get(`/equipment/search/${search}/${page}`)
    .then(json => {
      if (json.data.length !== 0) {
        dispatch(equipmentSearchSlice.actions.getEquipments(json))
      }
      dispatch(equipmentSearchSlice.actions.setWasSearched(true))
      dispatch(equipmentSearchSlice.actions.setCurrentPage(page))
    })
    .catch((e) => {
      console.error(e)
      alert('Во время поиска произошла ошибка.')
    })
}

export default equipmentSearchSlice.reducer
