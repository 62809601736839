import { FC, useEffect } from 'react'
import { PageTemplate } from '../../ui/templates'
import { Accordion, EmptyResultMessage } from '../../ui'
import { FileLink } from '../../ui'
import { SearchBar } from '../../features'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import { questionListsLoaded } from './questionListsSlice'
import { IQuestionList } from './types'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import './styles.scss'
import { questionnairesFileRegEx } from '../../config/constants'
import { useTranslation } from 'react-i18next'

export const QuestionsLists: FC = () => {
  const { t } = useTranslation()
  return (
    <PageTemplate title={t('Опросные листы')}>
      <SearchBar
        icon="icons/search-blue.svg"
        placeholder={t('Введите категорию оборудования')}
      />
      <div className="mt-24">
        <QuestionListsItems />
      </div>
    </PageTemplate>
  )
}

const QuestionListsItems: any = () => {
  const { t } = useTranslation()
  
  const searchBarValue = useSelector(
    (state: RootState) => state.searchBar.value,
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(questionListsLoaded())
  }, [dispatch])

  const questionLists: IQuestionList[] = useSelector(
    (state: RootState) => state.questionLists.data,
  )
  const fetching: boolean = useSelector(
    (state: RootState) => state.questionLists.fetching,
  )

  const filteredGroups = questionLists.filter((item: IQuestionList) => {
    for (let i = 0; i < item.questionnaires.length; i++) {
      const name = decodeURIComponent(item.questionnaires[i].file).match(
        questionnairesFileRegEx,
      )![1]
      if (name.toUpperCase().indexOf(searchBarValue.toUpperCase()) !== -1) {
        return true
      }
    }
  })

  if (fetching) {
    return <SkeletonList />
  }

  if (filteredGroups.length === 0) {
    return (
      <EmptyResultMessage>
        {t('По вашему запросу ничего не найдено.')}
      </EmptyResultMessage>
    )
  }

  return filteredGroups.map((item: IQuestionList) => {
    return (
      <Accordion
        title={item.name}
        arrowColor="grey"
        padding="p-12-16"
        fixOpened={searchBarValue !== ''}
        key={item.name}
      >
        {item.questionnaires.map(questionnaire => {
          return questionnaire.name.toUpperCase().indexOf(searchBarValue.toUpperCase()) !== -1 ? (
            <FileLink
              title={questionnaire.name}
              href={questionnaire.file}
              key={questionnaire.id}
            />
          ) : ''
        })}
      </Accordion>
    )
  })
}

const SkeletonList: FC = () => {
  return (
    <SkeletonTheme color="#E6EAF1">
      <div className="questions-lists-skeleton">
        <Skeleton count={10} height={49} />
      </div>
    </SkeletonTheme>
  )
}
