import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch, AppThunk } from '../../redux/configureStore'
import { Params, Request, State } from './types'
import { get, post } from '../../api/api'
import { filesDataCleared } from '../../features/dropbox/dropboxSlice'

const initialState: State = {
  data: [],
  fetching: false,
  error: false,
  creating: false,
  creatingError: false,
}

const requestsSlice = createSlice({
  name: 'requests',
  initialState,
  reducers: {
    loadStarted(state) {
      state.fetching = true
    },
    loadSucceed(state, action: PayloadAction<Request[]>) {
      state.fetching = false
      state.data = action.payload
    },
    loadFailed(state) {
      state.fetching = false
      state.error = true
    },
    createStarted(state) {
      state.creating = true
    },
    createSucceed(state, action) {
      state.creating = false
      state.data = [action.payload, ...state.data]
    },
    createFailed(state) {
      state.creating = true
      state.creatingError = true
    },
  },
})

export const requestsLoaded = (): AppThunk => (dispatch: AppDispatch) => {
  dispatch(requestsSlice.actions.loadStarted())
  get('/application')
    .then(json => {
      dispatch(requestsSlice.actions.loadSucceed(json.data))
    })
    .catch(() => {
      dispatch(requestsSlice.actions.loadFailed())
    })
}

export const requestCreated = (params: Params): AppThunk => (
  dispatch: AppDispatch,
) => {
  dispatch(requestsSlice.actions.createStarted())
  post('/application', params)
    .then(json => {
      dispatch(
        requestsSlice.actions.createSucceed({
          name: params.name,
          id: json.id,
          status: 'new',
        }),
      ) 
      dispatch(filesDataCleared())
    })
    .catch(() => {
      dispatch(requestsSlice.actions.createFailed())
      alert('Возникла ошибка при загрузке данных')
    })
}

export default requestsSlice.reducer
