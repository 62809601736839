import { FC, useEffect } from 'react'
import { SidebarMenuItem, StatusIndicator } from '../../../../ui'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux/configureStore'
import { requestsLoaded } from '../../../../pages/requests/requestsSlice'
import { useTranslation } from 'react-i18next'

export const MenuRequestsItem: FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(requestsLoaded())
  }, [dispatch])

  const requests = useSelector((state: RootState) => state.requests.data)
  const profile = useSelector((state: RootState) => state.profile.data)

  const pendingStatusRequest = requests?.find(item => item.status === 'pending')
  const newStatusRequest = requests?.find(item => item.status === 'new')

  const { t } = useTranslation()
  return (
    <SidebarMenuItem to="/requests" icon="icons/request.svg">
      <p className="mr-8">{t('Заявки')}</p>
      {profile?.user_type !== 'specialist'
        ? pendingStatusRequest && <StatusIndicator condition="pending" />
        : newStatusRequest && <StatusIndicator condition="new" />}
    </SidebarMenuItem>
  )
}
