import { Switch, Route, Redirect } from 'react-router-dom'
import {
  ContragentCheckPage,
  ContragentInfoPage,
  EquipmentItemPage,
  EquipmentSearchPage,
  EquipmentComparisonPage,
  ProfilePage,
  QuestionsLists,
  RequestsDetailPage,
  RequestsPage,
  TariffsPage,
  NotFoundPage,
} from './pages'

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/counterparty-check" />
      </Route>
      <Route exact path="/counterparty-check">
        <ContragentCheckPage />
      </Route>
      <Route exact path="/counterparty-check/:id">
        <ContragentInfoPage />
      </Route>
      <Route exact path="/equipment/comparison">
        <EquipmentComparisonPage />
      </Route>
      <Route exact path="/equipment/search">
        <EquipmentSearchPage />
      </Route>
      <Route exact path="/equipment/:id">
        <EquipmentItemPage />
      </Route>
      <Route exact path="/requests">
        <RequestsPage />
      </Route>
      <Route exact path="/requests/:id">
        <RequestsDetailPage />
      </Route>
      <Route exact path="/questionnaires">
        <QuestionsLists />
      </Route>
      <Route exact path="/profile">
        <ProfilePage />
      </Route>
      <Route exact path="/tariffs">
        <TariffsPage />
      </Route>
      <Route component={NotFoundPage} />
    </Switch>
  )
}
