import { FC, useState, useEffect, useRef } from 'react'
import './RadioButton.scss'

interface RadioButtonProps {
  label: string
  value: boolean
  onChange: () => void
  size?: number
}

export const RadioButton: FC<RadioButtonProps> = ({
  label,
  value,
  onChange,
  size = 24,
}) => {
  return (
    <label className="radio-button-container" onClick={onChange}>
      <input type="radio" className="radio-button" checked={value} />
      <img
        src={`/icons/radio${value ? '-checked' : ''}.svg`}
        alt=""
        className="button-image"
        style={{ width: size, height: size }}
      />
      {label}
    </label>
  )
}
