import { createPortal } from 'react-dom'
import { Box, Img } from '../../atoms'
import './Modal.scss'

type Props = {
  title?: string
  modalOpen: boolean
  setModalOpen: (par: boolean) => void
}

export const Modal: React.FC<Props> = ({
  modalOpen,
  setModalOpen,
  children,
  title,
}) => {
  if (!modalOpen) return null

  return createPortal(
    <div className="modal" onClick={() => setModalOpen(!modalOpen)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <Box color="white" padding="p-24">
          <header className="modal__header mb-16">
            <h2 className="modal__title">{title}</h2>
            <button
              className="modal__close-btn"
              onClick={() => setModalOpen(!modalOpen)}
              aria-label="Close modal"
            >
              <Img
                className="close-icon"
                src="icons/close-grey.svg"
                alt=""
                aria-hidden="true"
              />
            </button>
          </header>
          {children}
        </Box>
      </div>
    </div>,
    document.body,
  )
}
