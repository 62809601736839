import { FC } from 'react'
import { avatarSent } from './avatarSlice'
import { useDispatch } from 'react-redux'
import { Img } from '../../ui'
import './Avatar.scss'

interface AvatarProps {
  image: string
  onRemove: () => void
}

export const Avatar: FC<AvatarProps> = ({ image, onRemove }) => {
  const dispatch = useDispatch()

  const baseUrl = `${process.env.REACT_APP_BASE_URL}`

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      dispatch(avatarSent(e.target.files[0]))
    }
  }

  if (image) {
    return (
      <div className="avatar mb-16">
        <img src={`${baseUrl}/${image}`} alt="" className="avatar-img" />
        <Img
          src="icons/close-grey.svg"
          className="avatar-delete-icon"
          onClick={onRemove}
          alt=""
        />
      </div>
    )
  }

  return (
    <div className="mb-16">
      <label>
        <input style={{ display: 'none' }} type="file" onChange={onChange} />
        <a>
          <Img src="icons/avatar-empty.svg" alt="" className="avatar-img" />
        </a>
      </label>
    </div>
  )
}
