import { FC, useState, useLayoutEffect, useRef } from 'react'
import './Select.scss'
import { ErrorMessage } from '../../atoms/error-message/ErrorMessage'
import { Img } from '../../index'
// import { useDispatch } from 'react-redux'

interface SelectProps {
  title?: string
  value?: number
  name: string
  register?: any
  setValue?: any
  options: any
}

export const Select: FC<SelectProps> = ({
  title,
  value,
  name,
  register,
  setValue,
  options,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [currVal, setCurrVal] = useState<number>(value || 0)
  const [currText, setCurrText] = useState<string>(options.find((x: any) => x[0] == currVal)[1])
  const handleSelect = (val: number, caption: string) => {
    setValue(name, val)
    setCurrText(caption)
    setIsOpen(false)
  }
  
  // const dispatch = useDispatch()
  const ref = useRef<HTMLDivElement | null>(null)
  useLayoutEffect(() => {
    const onBodyClick = (e: any) => {
      if (ref.current && ref.current.contains(e.target)) {
        return
      }
      // dispatch(setIsOpen(false))
      setIsOpen(false)
    }
    document.body.addEventListener('click', onBodyClick, { capture: true })

    return () => document.body.removeEventListener('click', onBodyClick)
  }, [])
  
  return (
    <>
      <p className="input-title">{title}</p>
      <div className="select-wrapper" ref={ref}>
        <div
          className="select-subwrapper"
          onClick={e => {
            e.preventDefault()
            setIsOpen(!isOpen)
          }}
        >
          <span>{currText}</span>
          <input
            type="hidden"
            name={name}
            ref={register}
          />
          <button
            onClick={e => {
              e.preventDefault()
              setIsOpen(!isOpen)
            }}
            className="options-dropdown-button"
          >
            <Img alt="" src="icons/dropdown-grey.svg" />
          </button>
        </div>
        {isOpen && (
          <div className="options-dropdown">
            <ul>
              {options.map((val: any) => {
                return (
                  <li 
                    key={val[0]}
                    className="option"
                    onClick={() => handleSelect(val[0], val[1])}
                  >{val[1]}</li>
                )
              })}
            </ul>
          </div>
        )}
      </div>
    </>
  )
}
