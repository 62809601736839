import { FC } from 'react'
import Highlighter from 'react-highlight-words'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/configureStore'
import { Img } from '../../ui'
import './FileLink.scss'

interface FileLinkProps {
  title: string
  href: string
}

export const FileLink: FC<FileLinkProps> = ({ title, href }) => {
  const searchBarString = useSelector(
    (state: RootState) => state.searchBar.value,
  )

  const baseUrl = `${process.env.REACT_APP_BASE_URL}`

  return (
    <a
      className="file-link"
      href={baseUrl + href}
      target="_blank"
      rel="noreferrer"
    >
      <Img className="file-link-icon" src="icons/blue-file.svg" alt="" />
      <div className="file-link-title-grey">
        <Highlighter
          highlightClassName="file-link-title-blue"
          searchWords={[searchBarString]}
          textToHighlight={title}
        />
      </div>
    </a>
  )
}
