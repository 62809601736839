export const enLocale = {
  'Связанные аккаунты': 'Linked accounts',
  'Добавить пользователя': 'Add a user',
  'Вы можете привязать несколько аккаунтов, которые получат доступ к вашим заказам и смогут управлять ими': 'You can link a few accounts that will have access to your orders and will be able to manage them',
  'Отправлено приглашение': 'Invitation is sent',
  'Это обязательное поле': 'This is a required field',
  'Введен некорректный email': 'Invalid email',
  'Электронная почта': 'E-mail',
  'Добавить': 'Add',
  'Загрузка...': 'Loading...',
  'Перетащите сюда файл для загрузки или': 'Drag and drop the file to upload, or',
  'выберите его вручную': 'select it manually',
  'Проверка контрагента': 'Checking the counterparty',
  'Поиск оборудования': 'Looking for the equipment',
  'Опросные листы': 'Questionnaires',
  'Тарифы': 'Rates',
  'Выйти': 'Log out',
  'Заявки': 'Requests',
  'Убедитесь, что это значение содержит не менее 8 символов.': 'Make sure this value is at least 8 characters long.',
  'Пароли не совпадают': 'Passwords don\'t match',
  'Безопасность': 'Security',
  'Старый пароль': 'Previous password',
  'Новый пароль': 'New password',
  'Повторите новый пароль': 'Comfirm the password ',
  'Сохранить': 'Save',
  'Теплообменник': 'Heat exchanger',
  'Реактор': 'Reactor',
  'Гранулятор': 'Granulator',
  'Фильтр': 'Filter',
  'Ничего не найдено': 'Nothing is found',
  'Найти': 'Find',
  'Заявка сформирована': 'Request has been generated',
  'В работе': 'In process',
  'Требуется доработка': 'Modification is required',
  'Выполнена': 'is done',
  'Отменена': 'is cancelled',
  'Введите название компании': 'Please enter the company\'s name',
  'Возникла ошибка при загрузке данных.': 'An error occurred while loading data.',
  'Загружаем отчет...': 'Loading the report ...',
  'Сохранить в PDF': 'Save to PDF',
  'Общие сведения': 'General information',
  'Уставной капитал': 'Registered capital',
  'юаней': 'RMB',
  'Адрес': 'Address',
  'Сфера деятельности': 'Field of activity',
  'Тип компании': 'Company type',
  'Юридическое лицо': 'Legal entity',
  'Физическое лицо': 'Private entity ',
  'Регистрационный номер': 'Registration number',
  'Статус': 'Stage',
  'Работает': 'Works',
  'Дата основания': 'Founded in',
  'Дата утверждения': 'Approval date',
  'Код организации': 'TIN （VAT）',
  'Получите полную информацию о контрагенте': 'Get complete information about the counterparty',
  'Узнайте долговые обязательства, рейтинг контрагента и прочую детальную информацию, чтобы быть уверенным в сделке': 'Find out the debt obligations, counterparty rating and other detailed information to be sure of the transaction',
  'Оформить подписку': 'Subscribe',
  'Скачать полную версию': 'Download the full version',
  'У вас уже есть доступ к этому отчету': 'You have access to this report',
  'в этом месяце': 'this month',
  'Сведения о персонале': 'Employees details',
  'Контактная информация': 'Contact Information',
  'Телефон': 'Phone number',
  'Сайт': 'Web-site',
  'Информация об изменениях': 'Information of changes',
  'До': 'Before',
  'После': 'After',
  'Филиалы': 'Branches',
  'Нет филиалов или нет информации о них': 'There aren\'t any branches or information about them',
  'Сведения об акционерах': 'Information about shareholders',
  'Акционер': 'A shareholder',
  'Тип': 'Type',
  'Физ. лицо': 'Legal entity',
  'Юр. лицо': 'Private entity ',
  'Первоначальный взнос (10тыс.¥)': 'Down payment (10 000 ¥)',
  'Доля': 'Percentage',
  'Дата': 'Date',
  'Загружаем данные по компании.': 'We are loading data…',
  'Нужно подождать еще немного...': 'Please, wait',
  'Результаты поиска': 'Search results',
  'Условия поставки': 'Terms of delivery',
  'Пункт поставки': 'Delivery point',
  'Код ТН ВЭД': 'HS Code',
  'Наименование отправителя': 'Shipper\'s name',
  'Страна происхождения товара': 'Country of origin',
  'Страна отправления': 'Country of departure',
  'Изготовитель': 'Manufacturer',
  'Модели, артикулы...': 'Model, article number',
  'Кол-во ед. изм.': 'Quantity of unit measure',
  'Единица измерения': 'Unit of measure',
  'Вес нетто': 'Net weight',
  'Фактурная стоимость': 'Invoice value',
  'Статистическая стоимость': 'Statistical value',
  'Таможенная стоимость': 'Customs value',
  'Валюта таможенной стоимости': 'Currency of customs value ',
  'Таможенный орган': 'Customs authority',
  'Дата завершения таможенного оформления': 'Date of completion of customs clearance',
  'Дата выдачи Свидетельства о включении в Реестр таможенных брокеров (представителей)': 'Date of issue of the certificate of inclusion in the Register of customs brokers (representatives) ',
  'Дата курса валюты': 'Currency exchange rate date',
  'Таможенный режим': 'Customs regulations',
  'Код региона отправителя': 'Shipper\'s zipcode',
  'Итоговый адрес отправителя': 'Shipper\'s address',
  'Общее количество комплектов ': 'Total quantity of sets',
  'Всего наименований товаров': 'Total product names (items)',
  'Количество мест': 'Quantity of places',
  'Код вида таможенной декларации': 'Customs declaration type code',
  'Код региона получателя': 'Consignee\'s zipcode',
  'Получатель': 'Consignee',
  'Страна получателя': 'Consignee\'s country',
  'Адрес получателя': 'Consignee\'s address',
  'Наименование контрактодержателя': 'Name of the contract holder',
  'Код страны контрактодержателя': 'Contract holder\'s zipcode',
  'Адрес контрактодержателя': 'Contract holder\'s address',
  'Код торгующей страны': 'Trading country code',
  'Наименование декларанта': 'Declarant\'s name',
  'Страна декларанта': 'Declarant\'s country',
  'Адрес декларанта': 'Declarant\'s address',
  'Код страны отправителя': 'Country of a sender zipcode',
  'Страна назначения': 'Country of destination',
  'Кол-во транспортных средств при отправлении': 'Quantity of vehicles at departure',
  'Местонахождение товаров': 'location of the goods',
  'Код СОАТО получателя': 'Recipient\'s soato code ',
  'Код СОАТО контрактодержателя': 'Contract holder\'s soato code ',
  'Код валюты цены договора': 'Contract price currency code',
  'Общая сумма по счету': 'Total amount of Invoice',
  'Курс валюты': 'Currency exchange rate',
  'Код СОАТО отправителя': 'Sender\'s soato code ',
  'Код СОАТО декларанта': 'Declarant\'s soato code',
  'Код таможни на границе': 'Customs code at the border',
  'Товарный знак': 'Trademark',
  'Код ОКПО банка/паспорт сделки': 'OKPO ( RNNBO) code/passport of deal',
  'Код наличия упаковки товара': 'Batch code/Product packaging availability code',
  'Количество контейнеров': 'Quantity of containers',
  'Код единицы измерения, отличной от основной и дополнительной': 'Unit code other than the main and supplementary units',
  'Преференции': 'Preferences',
  'Процедура': 'Pocedure',
  'Вес брутто': 'Brutto weight',
  'Квота': 'Quota limit',
  'Код дополнительной единицы измерения': 'Additional unit code',
  'Признак корректировки таможенной стоимости': 'Sign of customs value adjustment',
  'Метод определения таможенной стоимости': 'Method for determining the customs value',
  'Предыдущее значение таможенной стоимости': 'Previous customs value',
  'Цена РУБ за ед изм': 'RUB price per unit',
  'Цена USD за ед изм': 'Price USD per unit',
  'Цена USD за кг': 'Price USD per kg',
  'Номер Свидетельства о включении в Реестр таможенных брокеров (представителей)': 'Number of the Certificate of inclusion in the Register of customs brokers (representatives)',
  'Общая сумма ТП, подлеж.взысканию/возврату по товару': 'The total amount of the TC subject to collection/refund for the product',
  'Получите полную информацию об оборудовании': 'Get full information about the equipment',
  'Find out the cost, weight, manufacturer and other parameters of the imported equipment': 'Find out the cost, weight, manufacturer and other parameters of the imported equipment',
  'Подключить тариф': 'Apply a tariff',
  'Загружаем данные по оборудованию.': 'We are uploading equipment data. Wait, please.',
  'Введите название оборудования': 'Enter the name of the equipment',
  'Похоже, вы заблудились': 'Page not found',
  'Cтраница, которую вы ищете, была перемещена, удалена, переименована или никогда не существовала': 'The page you are looking for has been moved, deleted, renamed, or never existed',
  'На главную': 'To the main page',
  'Профиль': 'User profile',
  'Персональные данные': 'Personal data',
  'Имя': 'Name',
  'Должность': 'Position',
  'Сервис': 'Service',
  'Язык': 'Language',
  'Организация': 'Company',
  'Название': 'Name',
  'Не указан': 'Not specified',
  'Юридический адрес': 'Registered address',
  'Введите категорию оборудования': 'Enter the equipment category',
  'По вашему запросу ничего не найдено.': 'Nothing is found for your query.',
  'Все заявки': 'All requests',
  'Отменить заявку': 'Cancel the request',
  'Завершить заявку': 'Complete the request',
  'E-mail пользователя': 'User\'s email',
  'E-mail специалиста': 'Specialist\'s e-mail',
  'Отправить': 'Send',
  'Отправить сообщение': 'Send a message',
  'Тема': 'Issue',
  'Сообщение': 'Message',
  'Заявка создана': 'Request has been generated',
  'Мы взяли в обработку вашу заявку. Если потребуются корректировки, технический специалист свяжется с вами. Если у вас появились вопросы, вы так же можете направить их на электронную почту специалиста, либо связаться со специалистом, отправив ему сообщение в заявке.': 'Your application is in procces. If adjustments are required, a technician will contact you. If you have any questions, you can send them to the specialist\'s e-mail, or contact the specialist by sending a message in the application.',
  'Загружен документ': 'File is uploaded',
  'Загружены документы': 'Uploaded files',
  'Создать заявку': 'Create a request',
  'У вас пока нет ни одной заявки.': 'You don\'t have any requests yet.',
  'Завершённые заявки': 'Completed requests',
  'Название заявки': 'Request name',
  'Приложите к заявке': 'Attach ',
  'опросный лист': 'A questionnaire to the request',
  'и другие документы, которые помогут нам подобрать оборудование': 'and other documents that will help us choose the equipment',
  'Создать': 'Create',
  'У вас подключен': 'You have activated',
  'Действует до': 'Valid until',
  'в месяц': 'per month',
}