import { FC, useState, useEffect, useRef, useMemo } from 'react'
import { IconButton } from '../../../ui'
import './ComparisonTable.scss'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { searchBarStringSet } from '../../../features/search-bar/searchBarSlice'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromComparison } from '../../../pages/equipment-comparison/equipmentComparisonSlice'
import { RootState } from '../../../redux/configureStore'
import { equipmentFields } from '../../../data/equipmentFields'

interface ComparisonTableProps {
  items: any[]
  viewMode: string
}

export const ComparisonTable: FC<ComparisonTableProps> = ({
  items,
  viewMode,
}) => {
  const { t } = useTranslation()
  const containerRef = useRef<HTMLTableElement>(null)
  const [maximumItems, setMaximumItems] = useState(0)
  const [firstVisibleItemIndex, setFirstVisibleItemIndex] = useState(0)
  const [openedSpoilers, setOpenedSpoilers] = useState(
    new Array(items.length).fill(null),
  )
  const dispatch = useDispatch()
  const profile = useSelector((state: RootState) => state.profile)
  const hasAccess = useMemo(
    () =>
      profile?.data?.company?.active_invoice ||
      profile?.data?.user_type === 'specialist',
    [profile?.data?.company?.active_invoice, profile?.data?.user_type],
  )

  useEffect(() => {
    const containerWidth = containerRef.current?.offsetWidth
    if (containerWidth) {
      setMaximumItems(Math.floor((containerWidth - 180) / 260))
    }
  }, [containerRef.current?.offsetWidth])

  const visibleItems = items.slice(
    firstVisibleItemIndex,
    firstVisibleItemIndex + maximumItems,
  )

  let filteredEquipmentFields = equipmentFields.filter(
    field =>
      field &&
      (!field.renderingCondition ||
        items.find(
          item =>
            field.renderingCondition &&
            field.renderingCondition(hasAccess, item[field.fieldNameKey]),
        )),
  )
  if (viewMode === 'differences' && items.length > 1) {
    filteredEquipmentFields = filteredEquipmentFields.filter(field => {
      if (!field) return false
      const itemsWithFirstPropertyValue = items.filter(
        item => item[field.fieldNameKey] === items[0][field.fieldNameKey],
      )
      return itemsWithFirstPropertyValue.length < items.length
    })
  }
  
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div className="table-container" ref={containerRef}>
      <table className="table">
        <tbody>
          <tr
            className={`row${
              containerRef.current &&
              containerRef.current.offsetTop - scrollPosition < 0
                ? ' row--sticky'
                : ''
            }`}
            key="name"
          >
            <th scope={'row'} className="row-heading">
              {t('Наименование')}
              {firstVisibleItemIndex > 0 && (
                <div className="scroll-items-button-left">
                  <IconButton
                    type="round"
                    onClick={() =>
                      setFirstVisibleItemIndex(firstVisibleItemIndex - 1)
                    }
                    icon="icons/arrow-left.svg"
                  />
                </div>
              )}
            </th>
            {visibleItems.map((item: any, key: number) => (
              <td className="table-data" key={key}>
                <div className="table-data-contents">
                  <div className="table-data-text">
                    {item['name']?.length &&
                    item['name'].length > 70 &&
                    openedSpoilers.indexOf(visibleItems[key].id) === -1 ? (
                      <>
                        {item['name']?.slice(0, 70)}...{' '}
                        <span
                          className="link"
                          onClick={() => {
                            setOpenedSpoilers([
                              ...openedSpoilers,
                              visibleItems[key].id,
                            ])
                          }}
                        >
                          Показать&nbsp;всё
                        </span>
                      </>
                    ) : item['name'] !== '' ? (
                      item['name']
                    ) : (
                      '—'
                    )}
                    <div className="mt-4">
                      <Link
                        to={`/equipment/${visibleItems[key].id}`}
                        onClick={() => dispatch(searchBarStringSet(''))}
                      >
                        {t('Перейти к товару')}
                      </Link>
                    </div>
                  </div>
                  <div
                    className="remove-item-button"
                    onClick={() =>
                      dispatch(removeFromComparison(visibleItems[key].id))
                    }
                  >
                    <img
                      src="/icons/close-grey.svg"
                      alt=""
                      className="remove-item-button-icon"
                    />
                  </div>

                  {firstVisibleItemIndex + maximumItems < items.length && (
                    <div className="scroll-items-button-right">
                      <IconButton
                        type="round"
                        onClick={() =>
                          setFirstVisibleItemIndex(firstVisibleItemIndex + 1)
                        }
                        icon="icons/arrow-right.svg"
                      />
                    </div>
                  )}
                </div>
              </td>
            ))}
          </tr>
          {filteredEquipmentFields.map(
            field =>
              field && (
                <tr className="row" key={field.fieldNameKey}>
                  <th scope={'row'} className="row-heading">
                    {field.formatName
                      ? field.formatName(field.fieldName)
                      : t(field.fieldName)}
                  </th>
                  {visibleItems.map((item: any, key: number) => (
                    <td className="table-data" key={key}>
                      {field.format
                        ? field.format(item[field.fieldNameKey] || '', dispatch)
                        : !!item[field.fieldNameKey]
                        ? item[field.fieldNameKey]
                        : '—'}
                    </td>
                  ))}
                </tr>
              ),
          )}
        </tbody>
      </table>
    </div>
  )
}
